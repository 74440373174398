import React, { useState, useCallback, useContext, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import { enviar, enviarJson } from "../../services/BaseService";
import Card from "../common/Card";
import FormAct from "../common/FormAct";
import SelectAct from "../mui/SelectAct";
import ButtonAct from "../mui/ButtonAct";
import SesionContext from "../../contexts/SesionContext";

const BaseConsolidada = (props) => {
    const sesionCtx = useContext(SesionContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const [fecha, setFecha] = useState(0);
    const [fechaConsolidada, setFechaConsolidada] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cargas, setCargas] = useState([]);

    const getCargas = useCallback(async () => {
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviar().get(`historicos/${sesionCtx.cliente.ruc}/cargas-y-salidas/`);
            if (response.status === 200) {
                setCargas(response.data);
            }
        } catch (error) { } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [sesionCtx]);

    const onChangeFecha = useCallback((event) => {
        const value = event.target.value;
        setFecha(value);
        if (value > 0) {
            const fechaSeleccionada = cargas[value - 1];
            setFechaConsolidada(fechaSeleccionada || null);
        } else {
            setFechaConsolidada(null);
        }
    }, [cargas]);

    const onClickDescargar = useCallback(async () => {
        setLoading(true);
        try {
            const params = {
                rucCliente: sesionCtx.cliente.ruc,
                cargaSalidaDto: fechaConsolidada,
            };
            const response = await enviarJson().post('base-consolidada/', params);
            setLoading(false);
            if (response.status === 200) {
                const byteCharacters = atob(response.data);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    let slice = byteCharacters.slice(offset, offset + 512);

                    let byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const reporteExcel = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(reporteExcel);
                let nombreEmpresa=sesionCtx.cliente.nombre;
                if(nombreEmpresa.endsWith(".")){
                    nombreEmpresa=nombreEmpresa.slice(0, -1);
                }
                downloadLink.download = `Base consolidada ${nombreEmpresa}.xlsx`;
                downloadLink.click();
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [sesionCtx.cliente.ruc, sesionCtx.cliente.nombre, fechaConsolidada]);

    useEffect(() => {
        if (firstLoad) {
            getCargas();
            setFirstLoad(false);
        }
    }, [getCargas, firstLoad])

    return (
        <Card className="derecha" titulo="Base consolidada de Jubilación Patronal y Bonificación por Desahucio" descripcion="Seleccione los datos para descargar la base consolidada">

            <FormAct>
                <FormControl className='control'>
                    <InputLabel id="lbl-fecha" className='input-label'>Fecha</InputLabel>
                    <SelectAct
                        labelId="lbl-fecha"
                        id="sel-fecha"
                        value={fecha}
                        label="Fecha"
                        onChange={onChangeFecha}>
                        <MenuItem className="menuItem" value={0}>Seleccione</MenuItem>
                        {cargas?.length > 0 && cargas?.map((carga, idx) => (
                            <MenuItem key={`${idx}_carga`} className="menuItem" value={(idx + 1)}>
                                {carga?.fechaParaComboInterfaz}
                            </MenuItem>
                        ))}
                    </SelectAct>
                </FormControl>

                <FormControl className='control-m0'>
                    <ButtonAct
                        variant="contained"
                        disabled={!fechaConsolidada || loading}
                        startIcon={loading && <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />}
                        onClick={onClickDescargar}
                    >
                        Descargar Base Consolidada
                    </ButtonAct>

                </FormControl>

            </FormAct>
            <p className="informacion">Base consolidada hasta la fecha de valoración, que presenta la composición histórica de las provisiones, es decir, qué porción de las mismas se consideró como un gasto y qué porción como pérdidas o ganancias actuariales. Este desglose se incluye por cada año desde el primer estudio actuarial consecutivo realizado por ACTUARIA para la compañía.</p>
        </Card>
    );


}

export default BaseConsolidada;