import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { usePromiseTracker } from "react-promise-tracker";
import ReactDOM from 'react-dom';
import ListAdvertencias from '../ListAdvertencias';
import ListErrores from '../ListErrores';
import classes from './Modal.module.css';
import { Audio } from 'react-loader-spinner'
import iconoOk from '../../assets/images/ok.svg';
import SesionContext from '../../contexts/SesionContext';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

Backdrop.propTypes={
  onClose: PropTypes.func
}

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

ModalOverlay.propTypes={
  children: PropTypes.node
}

const MensajeOk = (props) => {
  return (
    <div style={{ display: 'flex', color: '#3061AA' }}>
      <img src={iconoOk} alt="Ok" title="Ok" />
      <p style={{ marginLeft: '10px' }}>{props.descripcion}</p>
    </div>
  );
}

MensajeOk.propTypes={
  descripcion: PropTypes.string
}

const portalElement = document.getElementById('overlays');

const Modal = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  const sesionCtx = useContext(SesionContext);

  const loadingIndicator = (load) => {
    let html;
    if (load) {
      html = (
        <div style={{ width: '20px', float: 'right' }}>
          <Audio
            height="20"
            width="20"
            radius="9"
            color='#DF5E2D'
            ariaLabel='three-dots-loading'
          />
        </div>
      );
    }
    return html;
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay>
          {loadingIndicator(promiseInProgress  || sesionCtx.loading)}
          <h1>{props.titulo}</h1>
          <div style={{overflow:'auto',maxHeight:'420px'}}>
          {props.respuesta != null && <ListErrores respuesta={props.respuesta} />}
          {props.respuesta != null && <ListAdvertencias respuesta={props.respuesta} />}
          {props.respuesta != null && props.respuesta.cargaOk === true && <MensajeOk descripcion={props.respuesta.informativas[0]} />}
          </div>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

Modal.propTypes={
  onClose: PropTypes.func,
  titulo:PropTypes.string,
  children:PropTypes.node,
  respuesta: PropTypes.shape({
    errores:PropTypes.shape([]),
    advertencias:PropTypes.shape([]),
    informativas:PropTypes.shape([]),
    cargaOk:PropTypes.bool,
    tipo:PropTypes.string
  })
}

export default Modal;