import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, styled } from "@mui/material";

const StyleAct = styled(Autocomplete)(() => {
    return (
        {

            '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root':{
                fontWeight:'bold',
                fontSize:'17px',
            },

            '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root':{
                color: '#151F47',
            },

            '& .MuiInputLabel-outlined':{
                color: '#151F47',
            },

            '& .MuiAutocomplete-input':{
                color: '#151F47',
            },

            '& .MuiInputBase-root': {
                fontFamily: 'Montserrat, sans-serif',
                color: '#151F47',
               // fontWeight:'bold',
                
                '&:hover fieldset': {
                    borderColor: '#CB8D74',

                },
                '&.Mui-focused fieldset': {
                    borderColor: '#CB8D74',
                    fontWeight: 'bold',
                },

            },

            
            '& .MuiFormLabel-root': {
                fontFamily: 'Montserrat, sans-serif'
            },

            '& .MuiFormHelperText-root': {
                fontFamily: 'Montserrat, sans-serif'
            }
        }

    );
})

const AutocompleteAct = (props) => {
    return (
        <StyleAct
            disablePortal={props.disablePortal}
            freeSolo={props.freeSolo}
            id={props.id}
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            getOptionSelected={props.getOptionSelected}
            onChange={props.onChange}
            renderInput={props.renderInput}
            forcePopupIcon={props.forcePopupIcon}
            sx={{ width: 400 }}
        >
            {props.children}
        </StyleAct>
    );
}

AutocompleteAct.propTypes={
    id: PropTypes.string,
    disablePortal: PropTypes.bool,
    freeSolo: PropTypes.bool,
    options: PropTypes.array,
    getOptionLabel: PropTypes.func,
    getOptionSelected: PropTypes.func,
    onChange: PropTypes.func,
    renderInput: PropTypes.func,
    forcePopupIcon:PropTypes.bool,
    children: PropTypes.node
}

export default AutocompleteAct;