import React from 'react';
import PropTypes from 'prop-types';

import './FormAct.css'

const FormAct = (props) => {
    return (
        <form id={props.id} className='form' onSubmit={props.onSubmit?props.onSubmit:()=>{}}>
            {props.children}
        </form>
    );
};

FormAct.propTypes={
    id: PropTypes.string,
    onSubmit:PropTypes.func,
    children:PropTypes.node
}

export default FormAct;
