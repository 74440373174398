import axios from "axios";
import { appConfig } from '../config';

const enviarMultipart=()=>{
    return axios.create({
        baseURL: appConfig.apiEndpont,
        headers: {
            "Content-Type": "multipart/form-data;"
        }
    });
}

export const enviar=()=>{
    return axios.create({
        baseURL: appConfig.apiEndpont    
    });
}

export const enviarJson = () => {
    return axios.create({
        baseURL: appConfig.apiEndpont,
        headers: {
            'Content-Type': 'application/json',
        }
    })
};

export const enviarAuthorizationJson = (token) => {
    return axios.create({
        baseURL: appConfig.apiEndpont,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
};

export default enviarMultipart;
