import React, { useContext, useState } from 'react';
import { FormControl } from '@mui/material';
import SesionContext from '../../contexts/SesionContext';


import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Card from '../common/Card';
import FormAct from '../common/FormAct';
import TextFieldAct from '../mui/TextFieldAct';
import ButtonAct from '../mui/ButtonAct';

import { trackPromise } from 'react-promise-tracker';

const SeleccionCliente = (props) => {

    const [id, setId] = useState(null);
    const [ruc, setRuc] = useState('');
    const [nombre, setNombre] = useState(null);
    const [nombreComercial, setNombreComercial] = useState(null);
    const [encontrado, setEncontrado] = useState(null);
    const [mensaje, setMensaje] = useState(null);

    const sesionCtx = useContext(SesionContext);

    const navigate = useNavigate();


    const onClickContinuar = (event) => {

        console.log('continuar...')

        sesionCtx.cambiarCliente(id, ruc, nombre, nombreComercial);
        navigate("/carga-de-archivo");
    }

    const onChangeRuc = (event) => {
        setRuc(event.target.value)
        setNombre(null);
        setNombreComercial(null);
        setId(null);
        setEncontrado(null);
        setMensaje(null);
    }

    const onBlurRuc = (event) => {

        console.log("->" + ruc)


        const url = '/api/Account/search-by-ruc/' + ruc.trim();
        //consumir servicio
        //https://microservicio-salesforce-prueba-gdlikkj3bq-uc.a.run.app/api/Account/search-by-ruc/%7Bruc-empresa%7D
        trackPromise(
            axios.create({
                baseURL: process.env.REACT_APP_API_SALESFORCE
                //headers:{
                //    "Content-Type":"multipart/form-data;"
                //}



            }).get(url).then(function (response) {
                console.log("status: " + response.status);
                console.log("id: " + response.data.output.id);
                console.log("ruc: " + response.data.output.N_Identificacion__c);
                console.log("nombre: " + response.data.output.Name);
                console.log("nombre com: " + response.data.output.Nombre_comercial__c);
                setId(response.data.output.id);
                setRuc(response.data.output.N_Identificacion__c);
                setNombre(response.data.output.Name);
                setNombreComercial(response.data.output.Nombre_comercial__c);
                setEncontrado(true);
                setMensaje(null);
            }).catch(function (error) {


                if (error.response.status === 404) {
                    setNombre(null);
                    setEncontrado(false);
                    setMensaje('No encontrado');
                    
                } else {
                    console.log(error);
                    setNombre(null);
                    setEncontrado(null);
                    setMensaje('No se ha podido consultar el RUC. Vuelva a intentar en unos minutos.');
                }

            }));


    }

    return (



        <Card className="todo-el-ancho" titulo="Seleccionar cliente:" descripcion="Una vez ingresado el RUC, confirme el nombre del cliente y presione continuar.">

            <FormAct>

                <FormControl className='control'>
                    <TextFieldAct
                        id="txt-ruc"
                        placeholder="Ej. 1717171717001"
                        label="Ingrese RUC del cliente"
                        onChange={onChangeRuc}
                        autoComplete='off'
                        onBlur={onBlurRuc}
                        InputLabelProps={{
                            shrink: true
                        }}

                    />
                </FormControl>

                {encontrado === true && nombre !== null && <div className='nombre-cliente'>{nombre}</div>}
                {mensaje !== false && <div style={{
                    fontSize: '14px', marginTop: '14px',
                    fontWeight: 'bold', color: '#3061AA'
                }}>{mensaje}</div>}


                <FormControl>

                    <ButtonAct variant="contained" onClick={onClickContinuar} disabled={!(encontrado && nombre != null)}>CONTINUAR</ButtonAct>

                </FormControl>

            </FormAct>

        </Card>

    );
}

export default SeleccionCliente;