import React, { useEffect, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { useAuthState } from "react-firebase-hooks/auth";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { auth } from "../../lib/firebase";
import Header from "./Header";
import MenuPanel from "./MenuPanel";
import './Layout.css';
import SesionContext from '../../contexts/SesionContext';

const Layout = () => {
	const [mostrarMenu, setMostrarMenu] = useState(false);
	const [user, loading] = useAuthState(auth);
	const location = useLocation();
	const sesionCtx = useContext(SesionContext);

	useEffect(() => {
		if (user && !loading) {
			if (location?.pathname !== '/error' && location?.pathname !== '/seleccion-de-cliente' && location?.pathname !== '/' && location?.pathname !== '/admin') {
				setMostrarMenu(true);
			} else {
				setMostrarMenu(false);
				if (location?.pathname == '/seleccion-de-cliente'){
					if(sesionCtx.cliente.id!=null){
						setMostrarMenu(true);		
					}
				}
				
			}
		} else {
			setMostrarMenu(false);
		}
	}, [user, loading, location?.pathname]);

	return (
		<div>
			<Header user={user} />
			<h1 className='mobile-titulo-plataforma'>SISTEMA DIGITAL DE PROVISIONES</h1>
			<div className="con-menu">
				{mostrarMenu && (
					<MenuPanel />
				)}
				<Outlet />
			</div>
		</div>
	);
}

export default Layout;