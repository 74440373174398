import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SesionContext from '../contexts/SesionContext';
import { signInWithGoogle, auth, logout } from "../lib/firebase";
import google from '../assets/images/google.png'
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { appConfig } from '../config';

const BtnGoogle = (props) => {
    const navigate = useNavigate();
    const sesionCtx = useContext(SesionContext);
    const [user, loading] = useAuthState(auth);

    const onClickLogin = useCallback(async () => {
        try {
            await signInWithGoogle();
            sesionCtx.cambiarSitioAdmin(true);
            navigate('/seleccion-de-cliente')
        } catch (error) {
            console.log(error)
        }
    }, [navigate, sesionCtx]);

    useEffect(() => {
		console.log('user', user)
		if (user && !loading) {
			const url = '/usuarios/es-rol-administrador/' + user.email + '/' + user.uid;

			axios.create({
				baseURL: appConfig.apiEndpont
			}).get(url).then(function (response) {
				console.log("admin? " + response.data);
				if (response.data === true) {
					user.getIdTokenResult(true)
						.then((idTokenResult) => {
							console.log(" layout token: " + idTokenResult)
							// Confirm the user is an Admin.
							if (!!idTokenResult.claims.admin) {
								// Show admin UI.
								//showAdminUI();
								console.log("admin");
								sesionCtx.cambiarSitioAdmin(true);
							} else {
								// Show regular user UI.
								//showRegularUI();
								console.log("NO admin");
							}
						})
						.catch((error) => {
							console.log(error);
						});
					if (sesionCtx.cliente.id == null) {
						navigate("/seleccion-de-cliente");
					} else {
						navigate("/carga-de-archivo");
					}

				} else {
					//entonces es cliente, obtiene los clientes asignados al usuario
					const urlClientesUsuarios = '/clientes/usuario/' + user.email;
					axios.create({ baseURL: appConfig.apiEndpont })
					.get(urlClientesUsuarios).then(function (response) {
						console.log("clientes? " + response.data);
						const clientes = response.data;
						console.log("total:" + clientes.length);
						if (clientes.length === 0) {
							console.error("no clientes asignados");
							logout();
						}
						if (clientes.length >= 1) {
							sesionCtx.cambiarCliente(clientes[0].id, clientes[0].identificacion, clientes[0].nombre);
							navigate("/inicio");
						}
					}).catch(function (error) {
						console.error(error);
						//navigate("/error")
					});
				}
			}).catch(function (error) {
				console.error(error);
				//navigate("/error")
			});
		}
	}, [user, loading, sesionCtx, navigate]);

    return (
        <div onClick={onClickLogin} style={{
            cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
            marginTop: '30px', boxShadow: '0px 3px 6px #00000029',
            borderRadius: '10px', opacity: '1', textAlign: 'center', padding: '20px',
        }}>

            <img src={google} alt='Logo Google' />
            <div style={{ marginLeft: '10px', color: '#151F47', fontSize: '15px', fontWeight: 'bold' }}>Google</div>
        </div>
    );
};

export default BtnGoogle;
