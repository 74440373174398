import React from 'react';
import BtnGoogle from '../BtnGoogle';
import Card from '../common/Card';
import SideLogin from '../common/SideLogin';


const Admin = () => {
    return (
        <div>
			<SideLogin />
			<Card className="login" titulo="Iniciar sesión">
                <BtnGoogle />
            </Card>
        </div>
    );
};

export default Admin;
