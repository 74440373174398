import React, { useState, useCallback, useContext, useEffect } from "react";
import { auth } from "../../lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FormControl, InputLabel, MenuItem, CircularProgress, FormHelperText, TextField } from "@mui/material";
import { InsertDriveFileOutlined, SimCardDownloadOutlined } from '@mui/icons-material';
import { enviar, enviarAuthorizationJson, enviarJson } from "../../services/BaseService";
import SesionContext from "../../contexts/SesionContext";
import SelectAct from "../mui/SelectAct";
import ButtonAct from "../mui/ButtonAct";
import FormAct from "../common/FormAct";
import Card from "../common/Card";
import AutocompleteAct from "../mui/AutocompleteAct";
import TooltipAct from "../mui/TooltipAct";
import IconoTooltip from "../common/IconoTooltip";

const ReporteObd = () => {
    const sesionCtx = useContext(SesionContext);
    const [user] = useAuthState(auth);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDetalle, setLoadingDetalle] = useState(false);
    const [loadingExcelGeneral, setLoadingExcelGeneral] = useState(false);
    const [loadingExcelDetalle, setLoadingExcelDetalle] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [fecha, setFecha] = useState(-1);
    const [tipoReporte, setTipoReporte] = useState(0);
    const [grupo, setGrupo] = useState(-1);
    const [fechaValoracion, setFechaValoracion] = useState(null);
    const [cargas, setCargas] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [reporte, setReporte] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    const [reporteDetalle, setReporteDetalle] = useState([]);
    const [cedula, setCedula] = useState(-1);
    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState('');
    const [desgloseTributario, setDesgloseTributario] = useState(false);

    const generarExcel = (fileName, base64) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            let slice = byteCharacters.slice(offset, offset + 512);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const reporteExcel = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(reporteExcel);
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const getCargas = useCallback(async () => {
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviar().get(`historicos/${sesionCtx.cliente.ruc}/cargas-y-salidas/`);
            if (response.status === 200) {
                setCargas(response.data);
            }
        } catch (error) { } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [sesionCtx]);

    const getGrupos = useCallback(async (carga) => {
        try {
            const response = await enviar().post(`historicos/grupos`, carga);
            if (response.status === 200) {
                setGrupos(response.data);
            } else {
                setGrupos([]);
            }
        } catch (error) {
            setGrupos([]);
        }
    }, []);

    const getUsuario = useCallback(async () => {
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviar().get(`usuarios/${sesionCtx.cliente.ruc}/${user?.email}`);
            if (response.status === 200 || response.status === 201) {
                setDesgloseTributario(response.data.desgloseTributario);
            }
        } catch (error) { } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [sesionCtx, user?.email]);

    const onChangeFecha = useCallback((event) => {
        const value = event.target.value;
        setFecha(value);
        if (value > -1) {
            const fechaSeleccionada = cargas[value];
            setFechaValoracion(fechaSeleccionada || null);
            getGrupos(fechaSeleccionada);
        } else {
            setFechaValoracion(null);
            setGrupos([]);
            setGrupo(-1);
        }
    }, [cargas, getGrupos]);

    const onChangeTipoReporte = (event) => setTipoReporte(event.target.value);

    const onChangeGrupo = (event) => setGrupo(event.target.value);

    const onChangeAutocompleteEmpleado = useCallback(async (event, newValue) => {
        if (newValue) {
            setLoadingDetalle(true);
            const value = newValue.cedula;
            console.log("valor:" + value);
            setCedula(value);
            if (value !== -1) {
                const emp = empleados?.filter((emp) => emp.cedula === value);
                setEmpleadoSeleccionado(emp[0]);
                const grupoDto = grupo !== -1
                    ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
                    : null;
                const params = {
                    grupoDto,
                    tipoReporte,
                    cargaSalidaDto: fechaValoracion,
                    cedulaSeleccionada: value,
                };
                try {
                    const response = await enviarJson().post(`base-consolidada/reporte-obd-detalle/${sesionCtx.cliente.ruc}`, params);
                    if (response.status === 200) {
                        setReporteDetalle(response.data);
                    }
                    setLoadingDetalle(false);
                } catch (error) {
                    setLoadingDetalle(false);
                    setReporteDetalle([]);
                }
            } else {
                setLoadingDetalle(false);
                setReporteDetalle([]);
                setEmpleadoSeleccionado(null);
            }
        }

    }, [grupo, fechaValoracion, empleados, grupos, tipoReporte, sesionCtx.cliente.ruc]);

    const onChangeEmpleado = useCallback(async (event) => {
        setLoadingDetalle(true);
        const value = event.target.value;
        setCedula(value);
        if (value !== -1) {
            const emp = empleados?.filter((emp) => emp.cedula === value);
            setEmpleadoSeleccionado(emp[0]);
            const grupoDto = grupo !== -1
                ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
                : null;
            const params = {
                grupoDto,
                tipoReporte,
                cargaSalidaDto: fechaValoracion,
                cedulaSeleccionada: value,
            };
            try {
                const response = await enviarJson().post(`base-consolidada/reporte-obd-detalle/${sesionCtx.cliente.ruc}`, params);
                if (response.status === 200) {
                    setReporteDetalle(response.data);
                }
                setLoadingDetalle(false);
            } catch (error) {
                setLoadingDetalle(false);
                setReporteDetalle([]);
            }
        } else {
            setLoadingDetalle(false);
            setReporteDetalle([]);
            setEmpleadoSeleccionado(null);
        }
    }, [grupo, fechaValoracion, empleados, grupos, tipoReporte, sesionCtx.cliente.ruc]);

    const onClickGenerar = useCallback(async () => {
        setLoading(true);
        setEmpleados([]);
        setReporte([]);
        setEmpleadoSeleccionado(null);
        setReporteDetalle([]);
        setCedula(-1);
        const grupoDto = grupo !== -1
            ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
            : null;
        const params = {
            grupoDto,
            tipoReporte,
            cargaSalidaDto: fechaValoracion,
            cedulaSeleccionada: null,
        };
        try {
            setGrupoSeleccionado(grupoDto != null ? grupoDto.nombre : 'Todos');
            const response = await enviarJson().post(`base-consolidada/reporte-obd/${sesionCtx.cliente.ruc}`, params);
            if (response.status === 200) {
                setEmpleados(response.data.empleados);
                setReporte(response.data.lista);
                setMensaje(response.data.lista.length > 0 ? '' : 'No existen trabajadores que pertenezcan al grupo seleccionado.');
            } else {
                setMensaje('No existen trabajadores que pertenezcan al grupo seleccionado.');
            }
            setLoading(false);
            //se pone el cursor al inicio del reporte
            const anchorElement = document.getElementById('reporte-obd');
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offsetTop: 0 });
            }

        } catch (error) {
            setLoading(false);
            setMensaje('No existen trabajadores que pertenezcan al grupo seleccionado.');
        }
    }, [grupo, fechaValoracion, grupos, tipoReporte, sesionCtx.cliente.ruc]);

    const onClickGenerarExcelGeneral = useCallback(async () => {
        setLoadingExcelGeneral(true);
        const grupoDto = grupo !== -1
            ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
            : null;
        const params = {
            grupoDto,
            tipoReporte,
            cargaSalidaDto: fechaValoracion,
            cedulaSeleccionada: null,
        };
        try {
            const token = await user.getIdToken();
            const response = await enviarAuthorizationJson(token).post(`base-consolidada/reporte-obd-excel/${sesionCtx.cliente.ruc}`, params);
            if (response.status === 200) {
                generarExcel(`Reporte Obd ${sesionCtx.cliente.ruc}`, response.data);
            }
        } catch (error) {
            console.log(error);
            alert('Error al generar el archivo');
        } finally {
            setLoadingExcelGeneral(false);
        }
    }, [grupo, fechaValoracion, grupos, tipoReporte, sesionCtx.cliente.ruc, user]);

    const onClickGenerarExcelDetalle = useCallback(async () => {
        setLoadingExcelDetalle(true);
        const grupoDto = grupo !== -1
            ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
            : null;
        const params = {
            grupoDto,
            tipoReporte,
            cargaSalidaDto: fechaValoracion,
            cedulaSeleccionada: cedula,
        };
        try {
            const token = await user.getIdToken();
            const response = await enviarAuthorizationJson(token).post(`base-consolidada/reporte-obd-excel/${sesionCtx.cliente.ruc}`, params);
            if (response.status === 200) {
                generarExcel(`Reporte Detalle Obd ${sesionCtx.cliente.ruc} - ${cedula}`, response.data);
            }
        } catch (error) {
            console.log(error);
            alert('Error al generar el archivo');
        } finally {
            setLoadingExcelDetalle(false);
        }
    }, [grupo, fechaValoracion, grupos, cedula, tipoReporte, sesionCtx.cliente.ruc, user]);

    const numberFormat = (number) => {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        return parseFloat(number).toFixed(2).toString().replace(exp, rep);
    }

    useEffect(() => {
        if (firstLoad) {
            getUsuario();
            getCargas();
            setFirstLoad(false);
        }
    }, [getCargas, getUsuario, firstLoad]);

    return (
        <Card className="derecha" titulo="Reporte histórico de Jubilación Patronal y Bonificación por Desahucio" descripcion="Seleccione los datos para generar el reporte">
            <FormAct>
                <FormControl className='control'>
                    <InputLabel id="lbl-fecha-valoracion" className='input-label'>Fecha de valoración</InputLabel>
                    <SelectAct
                        labelId="lbl-fecha-valoracion"
                        id="sel-fecha-valoracion"
                        value={fecha}
                        label="Fecha de Valoración"
                        onChange={onChangeFecha}>
                        <MenuItem className="menuItem" value={-1}>Seleccione</MenuItem>
                        {cargas?.length > 0 && cargas?.map((carga, idx) => (
                            <MenuItem key={`${idx}_carga`} className="menuItem" value={idx}>
                                {carga?.fechaParaComboInterfaz}
                            </MenuItem>
                        ))}
                    </SelectAct>
                </FormControl>

                <FormControl className='control'>
                    <InputLabel id="lbl-tipo-reporte" className='input-label'>Reporte histórico de provisión por</InputLabel>
                    <SelectAct
                        labelId="lbl-tipo-reporte"
                        id="sel-tipo-reporte"
                        value={tipoReporte}
                        label="Reporte histórico de provisión por"
                        onChange={onChangeTipoReporte}>
                        <MenuItem className="menuItem" value={0}>Seleccione</MenuItem>
                        <MenuItem className="menuItem" value="J">Jubilación Patronal</MenuItem>
                        <MenuItem className="menuItem" value="D">Bonificación por Desahucio</MenuItem>
                    </SelectAct>
                </FormControl>

                <FormControl className='control'>
                    <InputLabel id="lbl-grupo" className='input-label'>Grupo
                        <TooltipAct placement="top" arrow
                            title={
                                <React.Fragment>
                                    Grupos por estatus de trabajadores o por tiempo de servicio
                                </React.Fragment>
                            }
                        >
                            <span><IconoTooltip blanco={false} ancho={16} /></span>
                        </TooltipAct>
                    </InputLabel>
                    <SelectAct
                        labelId="lbl-grupo"
                        id="sel-grupo"
                        value={grupo}
                        label="Grupo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        onChange={onChangeGrupo}>
                        <MenuItem className="menuItem" value={-1}>Todos</MenuItem>
                        {grupos?.length > 0 && grupos?.map((grupo) => (
                            <MenuItem key={grupo.codigo} className="menuItem" value={grupo.codigo}>{grupo.nombre}</MenuItem>
                        ))}
                    </SelectAct>
                    <FormHelperText sx={{ fontFamily: 'Montserrat' }}>Seleccionar el grupo de interés para generar el reporte</FormHelperText>
                </FormControl>

                <FormControl className='control-m0'>
                    <ButtonAct
                        variant="contained"
                        disabled={!fechaValoracion || !tipoReporte || loading}
                        onClick={onClickGenerar}
                        startIcon={loading ? <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} /> : <InsertDriveFileOutlined />}
                    >
                        Generar
                    </ButtonAct>
                </FormControl>
            </FormAct>
            <p className="informacion">
                El reporte histórico de provisión presenta el detalle de la composición histórica de la provisión de Jubilación Patronal y Bonificación por Desahucio desde el primer estudio actuarial emitido por ACTUARIA para la entidad hasta la fecha de valoración, el cual se podrá generar por grupos de interés y posteriormente, por persona.
                <p style={{ marginTop: "6px" }}>TS: Tiempo de servicio</p>
            </p>
            <p style={{ fontWeight: "normal", textAlign: "justify" }}>
                Nota: Los datos presentados a continuación han sido <strong>estimados</strong> de acuerdo a la normativa tributaria vigente en cada período y los estudios actuariales.
                &nbsp;<em>&ldquo;La declaración hace responsable al declarante y, en su caso, al contador que firme la declaración, por la exactitud y veracidad de los datos que contenga.&ldquo; (Art. 101. LRTI.)</em>
            </p>
            <div id="reporte-obd">
                {reporte?.length > 0 && empleados?.length > 0 ? (
                    <div>
                        <table className="table-reporte">
                            <thead>
                                <tr>
                                    <th colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 6 : 4}>
                                        Reporte histórico de provisión - {grupoSeleccionado}
                                    </th>
                                    <th colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 5 : 4}>
                                        <ButtonAct
                                            color="verde"
                                            endIcon={loadingExcelGeneral
                                                ? <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                                                : <SimCardDownloadOutlined />}
                                            sx={{ width: '150px' }}
                                            disabled={loadingExcelGeneral}
                                            onClick={onClickGenerarExcelGeneral}
                                        >
                                            Excel
                                        </ButtonAct>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Año</td>
                                    <td>OBD Inicial
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Provisión al inicio del período del primer estudio actuarial de la empresa.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                    {(sesionCtx.sitioAdmin || desgloseTributario) && (
                                        <>
                                            <td>Gasto deducible estimado
                                                <TooltipAct placement="top" arrow color="blanco"
                                                    title={
                                                        <React.Fragment>
                                                            Estimación de gasto deducible de cada período.
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <span><IconoTooltip blanco={true} /></span>
                                                </TooltipAct>

                                            </td>
                                            <td>Gasto no deducible permanente
                                                <TooltipAct placement="top" arrow color="blanco"
                                                    title={
                                                        <React.Fragment>
                                                            Estimación del gasto no deducible permanente, que no generó impuestos diferidos.
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <span><IconoTooltip blanco={true} /></span>
                                                </TooltipAct>
                                            </td>
                                            <td>Gasto no deducible temporario
                                                <TooltipAct placement="top" arrow color="blanco"
                                                    title={
                                                        <React.Fragment>
                                                            Estimación del gasto no deducible temporario, que generó impuestos diferidos.
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <span><IconoTooltip blanco={true} /></span>
                                                </TooltipAct>
                                            </td>
                                        </>
                                    )}
                                    <td>Gasto total
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Suma del gasto anual más el OBD inicial, si el mismo fue reconocido en resultados.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>

                                    </td>
                                    <td>Pérdida o ganancia actuarial
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Pérdidas y ganancias actuariales según el estudio actuarial de cada año.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>

                                    </td>
                                    <td>Transferencias de provisión</td>
                                    <td>Reverso
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Provisiones no utilizadas.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                    <td>Pago
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Provisión utilizada para efectuar pagos al personal cesante.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                    <td>OBD Acumulado
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Provisión acumulada anual al final de cada periodo.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                </tr>
                                {reporte?.map((item, idx) => (
                                    <tr key={`${idx}_reporte`}>
                                        <td>{item.anio}</td>
                                        <td className={item.obdInicial < 0 && 'text-red'}>
                                            {numberFormat(item.obdInicial)}
                                        </td>
                                        {(sesionCtx.sitioAdmin || desgloseTributario) && (
                                            <>
                                                <td className={item.gastoDeducibleEstimado < 0 && 'text-red'}>
                                                    {numberFormat(item.gastoDeducibleEstimado)}
                                                </td>
                                                <td className={item.gastoNoDeduciblePermanente < 0 && 'text-red'}>
                                                    {numberFormat(item.gastoNoDeduciblePermanente)}
                                                </td>
                                                <td className={item.gastoNoDeducibleTemporario < 0 && 'text-red'}>
                                                    {numberFormat(item.gastoNoDeducibleTemporario)}
                                                </td>
                                            </>
                                        )}
                                        <td className={item.gastoTotal < 0 && 'text-red'}>
                                            {numberFormat(item.gastoTotal)}
                                        </td>
                                        <td className={item.perdidaGananciaOri < 0 && 'text-red'}>
                                            {numberFormat(item.perdidaGananciaOri)}
                                        </td>
                                        <td className={item.transferenciasDeProvision < 0 && 'text-red'}>
                                            {numberFormat(item.transferenciasDeProvision)}
                                        </td>
                                        <td className={item.reverso < 0 && 'text-red'}>
                                            {numberFormat(item.reverso)}
                                        </td>
                                        <td className={item.pago < 0 && 'text-red'}>
                                            {numberFormat(item.pago)}
                                        </td>
                                        <td className={item.obdAcumulado < 0 && 'text-red'}>
                                            {numberFormat(item.obdAcumulado)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="empleados">
                            <h3>Seleccionar la persona de quien desea visualizar el reporte</h3>
                            <div>
                                <AutocompleteAct
                                    disablePortal
                                    freeSolo
                                    id="autocomplete-empleado"
                                    options={empleados}
                                    getOptionLabel={(empleado) => empleado.nombreParaAutocomplete}
                                    getOptionSelected={(empleado) => empleado.cedula}
                                    onChange={onChangeAutocompleteEmpleado}
                                    forcePopupIcon={true}
                                    renderInput={(params) => <TextField {...params} label="Búsqueda por nombre o cédula" InputLabelProps={{
                                        shrink: true,
                                    }} />}
                                />


                            </div>
                        </div>
                        {!loadingDetalle && reporteDetalle?.length > 0 && (
                            <table className="table-reporte-detalle">
                                <thead>
                                    <tr>
                                        <th colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 11 : 8}>
                                            <div>
                                                <p>EVOLUCIÓN PERIODO: 2018 - 2022</p>
                                                <p>VALORES EN US$</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 4 : 3}>
                                            <strong>NOMBRE:</strong>
                                            {` ${empleadoSeleccionado.nombre}`}
                                        </td>
                                        <td colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 3 : 2}>
                                            <strong>CÉDULA:</strong>
                                            {` ${empleadoSeleccionado.cedula}`}
                                        </td>
                                        <td colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 4 : 3}>
                                            <strong>STATUS:</strong>
                                            {` ${empleadoSeleccionado.status}`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={(sesionCtx.sitioAdmin || desgloseTributario) ? 8 : 5}>
                                            Reporte histórico de provisión - {grupoSeleccionado}
                                        </td>
                                        <td colSpan={3}>
                                            <ButtonAct
                                                color="verde"
                                                endIcon={loadingExcelDetalle
                                                    ? <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                                                    : <SimCardDownloadOutlined />}
                                                sx={{ width: '150px' }}
                                                disabled={loadingExcelDetalle}
                                                onClick={onClickGenerarExcelDetalle}
                                            >
                                                Excel
                                            </ButtonAct>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Año</td>
                                        <td>OBD Inicial
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Provisión al inicio del período del primer estudio actuarial de la empresa.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        {(sesionCtx.sitioAdmin || desgloseTributario) && (
                                            <>
                                                <td>Gasto deducible estimado
                                                    <TooltipAct placement="top" arrow color="blanco"
                                                        title={
                                                            <React.Fragment>
                                                                Estimación de gasto deducible de cada período.
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <span><IconoTooltip blanco={true} /></span>
                                                    </TooltipAct>
                                                </td>
                                                <td>Gasto no deducible permanente
                                                    <TooltipAct placement="top" arrow color="blanco"
                                                        title={
                                                            <React.Fragment>
                                                                Estimación del gasto no deducible permanente, que no generó impuestos diferidos.
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <span><IconoTooltip blanco={true} /></span>
                                                    </TooltipAct>
                                                </td>
                                                <td>Gasto no deducible temporario
                                                    <TooltipAct placement="top" arrow color="blanco"
                                                        title={
                                                            <React.Fragment>
                                                                Estimación del gasto no deducible temporario, que generó impuestos diferidos.
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <span><IconoTooltip blanco={true} /></span>
                                                    </TooltipAct>
                                                </td>
                                            </>
                                        )}
                                        <td>Gasto total
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Suma del gasto anual más el OBD inicial, si el mismo fue reconocido en resultados.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>Pérdida o ganancia actuarial
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Pérdidas y ganancias actuariales según el estudio actuarial de cada año.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>Transferencias de provisión</td>
                                        <td>Reverso
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Provisiones no utilizadas.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>Pago
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Provisión utilizada para efectuar pagos al personal cesante.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>OBD Acumulado
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Provisión acumulada anual al final de cada periodo.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                    </tr>
                                    {reporteDetalle?.map((item, idx) => (
                                        <tr key={`${idx}_detalle`}>
                                            <td>{item.anio}</td>
                                            <td className={item.obdInicial < 0 && 'text-red'}>
                                                {numberFormat(item.obdInicial)}
                                            </td>
                                            {(sesionCtx.sitioAdmin || desgloseTributario) && (
                                                <>
                                                    <td className={item.gastoDeducibleEstimado < 0 && 'text-red'}>
                                                        {numberFormat(item.gastoDeducibleEstimado)}
                                                    </td>
                                                    <td className={item.gastoNoDeduciblePermanente < 0 && 'text-red'}>
                                                        {numberFormat(item.gastoNoDeduciblePermanente)}
                                                    </td>
                                                    <td className={item.gastoNoDeducibleTemporario < 0 && 'text-red'}>
                                                        {numberFormat(item.gastoNoDeducibleTemporario)}
                                                    </td>
                                                </>
                                            )}
                                            <td className={item.gastoTotal < 0 && 'text-red'}>
                                                {numberFormat(item.gastoTotal)}
                                            </td>
                                            <td className={item.perdidaGananciaOri < 0 && 'text-red'}>
                                                {numberFormat(item.perdidaGananciaOri)}
                                            </td>
                                            <td className={item.transferenciasDeProvision < 0 && 'text-red'}>
                                                {numberFormat(item.transferenciasDeProvision)}
                                            </td>
                                            <td className={item.reverso < 0 && 'text-red'}>
                                                {numberFormat(item.reverso)}
                                            </td>
                                            <td className={item.pago < 0 && 'text-red'}>
                                                {numberFormat(item.pago)}
                                            </td>
                                            <td className={item.obdAcumulado < 0 && 'text-red'}>
                                                {numberFormat(item.obdAcumulado)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {loadingDetalle && (
                            <div className="div-center">
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="contenedor-flex">
                        <h3>{mensaje}</h3>
                    </div>
                )}
            </div>
            <div style={{ height: '800px' }}>{' '}</div>
        </Card>
    );


}

export default ReporteObd;