import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from "@mui/material";
import { styled } from "@mui/material";

const StyleAct = styled(TextField)(() => {
    return (
        {
            '& .MuiOutlinedInput-input': {
                color: '#151F47',
                fontFamily: 'Montserrat, sans-serif',
            },

            '& .MuiFormLabel-root': {
                color: '#151F47',
                fontFamily: 'Montserrat, sans-serif', 
                letterSpacing: 'normal',
                fontSize:'18px',
                fontWeight:'bold',
                '&.Mui-focused': {
                    color: '#151F47',
                }
            },  

            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#CB8D74',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#CB8D74',
                },

            },
            '& .MuiFormHelperText-root':{
                fontFamily: 'Montserrat',
            }
        }

    );
})

const TextFieldAct = ({ value, onChange, onBlur, error, helperText, label, ...rest }) => {
    return (
        <StyleAct
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            helperText={helperText}
            value={value}
            error={error}
            {...rest}
        />
    );
}

TextFieldAct.propTypes={
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    helperText: PropTypes.string,
    value:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    error: PropTypes.bool,
    rest:PropTypes.shape([])
}

export default TextFieldAct;