import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FormControl } from "@mui/material";
import { updatePassword } from "firebase/auth";
import { auth } from "../../lib/firebase";
import Card from "../common/Card";
import FormAct from "../common/FormAct";
import ButtonAct from "../mui/ButtonAct";
import TextFieldAct from "../mui/TextFieldAct";
import { validarPassword } from '../util/Regex'
import Modal from '../common/Modal';




const CambiarPassword = (props) => {

    const [user] = useAuthState(auth);

    const [nuevaClave1, setNuevaClave1] = useState('');
    const [nuevaClave2, setNuevaClave2] = useState('');
    const [error, setError] = useState('');
    const [modalSimpleVisible, setModalSimpleVisible] = useState(false);
    const [respuesta, setRespuesta] = useState({ tipo: 'A', cargaOk: false, errores: [], advertencias: [], informativas: [] });

    const deshabilitarBotonCambiar = () => {
        return nuevaClave1 === "" || nuevaClave1 !== nuevaClave2
    }

    const onChangeNuevaClave1 = (event) => {
        setNuevaClave1(event.target.value);
        if (event.target.value !== '') {
            let mensaje = validarPassword(event.target.value,"Ingrese al menos 1 caracter y 1 dígito. Mínimo 8 caracteres.");
            if(mensaje===null){
                setError('')
            }else{
                setError(mensaje)
            }
        } else {
            setError('');
        }
    }


    const onChangeNuevaClave2 = (event) => {
        setNuevaClave2(event.target.value);
    }

    const onClickCambiar = () => {

        updatePassword(user, nuevaClave1).then(() => {
            setRespuesta({ tipo: 'A', cargaOk: true, errores: [], advertencias: [], informativas: ['Contraseña cambiada correctamente.'] });
            setModalSimpleVisible(true);
        }).catch((error) => {
            alert('error:'+error)
        });
    }

    const onCloseModalSimple = () => {
        setModalSimpleVisible(false);
    };

    return (
        <Card className="derecha" titulo="Cambio de Contraseña" descripcion="Ingrese su nueva contraseña dos veces">
            <FormAct>
                <FormControl className='control'>
                    <TextFieldAct
                        required
                        id="txt-nueva-clave"
                        label="Nueva contraseña"
                        type="password"
                        value={nuevaClave1}
                        helperText={error}
                        onChange={onChangeNuevaClave1}
                        error={error !== ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>

                <FormControl className='control'>
                    <TextFieldAct
                        required
                        id="txt-repetir-clave"
                        label="Repita la nueva contraseña"
                        type="password"
                        value={nuevaClave2}
                        onChange={onChangeNuevaClave2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>

                <ButtonAct variant="contained"
                    disabled={deshabilitarBotonCambiar()}
                    onClick={onClickCambiar}>Cambiar Contraseña</ButtonAct>
            </FormAct>

            {modalSimpleVisible &&
                <Modal respuesta={respuesta} titulo="Mensaje">
                    <div className="botones">
                        <ButtonAct variant="contained" onClick={onCloseModalSimple} color="gris">
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            }
        </Card >);


}

export default CambiarPassword;