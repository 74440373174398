import React, { useEffect, useState, useCallback, useContext } from 'react';
import Card from '../common/Card';
import FormAct from '../common/FormAct';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import ButtonAct from '../mui/ButtonAct';
import Modal from '../common/Modal';
import TextFieldAct from '../mui/TextFieldAct';
import { validarNombreOApellido, validarEmail } from '../util/Regex';
import SesionContext from '../../contexts/SesionContext';

import { enviar } from '../../services/BaseService';
import ListUsuarios from '../ListUsuarios';


const GestionDeUsuarios = (props) => {
    const sesionCtx = useContext(SesionContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const [usuarios, setUsuarios] = useState([]);

    const [uid, setUid] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [estado, setEstado] = useState('');
    const [desgloseTributario, setDesgloseTributario] = useState('no');

    const [errores, setErrores] = useState({ nombre: null, apellido: null, email: null });

    const [respuesta, setRespuesta] = useState({ tipo: 'N', cargaOk: false, errores: [], advertencias: [], informativas: [] });

    const [modalVisible, setModalVisible] = useState(false);

    const [modalSimpleVisible, setModalSimpleVisible] = useState(false);

    const onChangeNombre = (event) => {
        setNombre(event.target.value);
        console.log(event.target.value);
        let mensaje = validarNombreOApellido(event.target.value, 'El nombre tiene caracteres inválidos');
        setErrores((anterior) => { return { ...anterior, nombre: mensaje } });
    }

    const onChangeApellido = (event) => {
        setApellido(event.target.value);
        console.log(event.target.value);
        let mensaje = validarNombreOApellido(event.target.value, 'El apellido tiene caracteres inválidos');
        setErrores((anterior) => { return { ...anterior, apellido: mensaje } });
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
        console.log(event.target.value);
        let mensaje = validarEmail(event.target.value, 'Email incorrecto');
        console.log('email mensaje' + mensaje)
        setErrores((anterior) => { return { ...anterior, email: mensaje } });
    }

    const onChangeDesgloseTributario = (event) => {
        setDesgloseTributario(event.target.value);
    }

    const consultarUsuarios = useCallback(() => {
        enviar().get(`/usuarios/${sesionCtx.cliente.ruc}`).then((response) => {
            if (response.status === 200) {
                setUsuarios(response.data);
            } else {
                console.log("total usu:" + response.data.length)
            }
        }
        ).catch((error) => {
            console.log(error);
        })
    }, [sesionCtx.cliente.ruc]);

    useEffect(() => {
        if (usuarios?.length <= 0 && firstLoad && sesionCtx.cliente.ruc) {
            setFirstLoad(false);
            consultarUsuarios();
        }
    }, [sesionCtx, consultarUsuarios, firstLoad, usuarios]);

    const onClickCrearUsuario = useCallback(() => {
        setUid('');
        setNombre('');
        setApellido('');
        setEmail('');
        setEstado('A');
        setModalVisible(true);
    }, []);

    const onClickEditarUsuario = useCallback((usuario) => {
        setUid(usuario.uid);
        setNombre(usuario.nombre);
        setApellido(usuario.apellido);
        setEmail(usuario.identificador);
        setEstado(usuario.estado);
        setDesgloseTributario(usuario.desgloseTributario===true? 'si':'no');
        setErrores((anterior) => { return { ...anterior, email: null } });
        setModalVisible(true);
    }, []);

    const onChangeEstado = useCallback(async (event, usuario, index) => {
        const estadoUsuario = event.target.checked;
        usuario.estado = estadoUsuario ? 'A' : 'I';
        sesionCtx.cambiarLoading(true)
        try {
            const response = await enviar().put(`/usuarios/${sesionCtx.cliente.ruc}/`, usuario);
            if (response.status === 200 || response.status === 201) {
                usuarios[index].estado = usuario.estado;
            }
        } catch (error) { } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [sesionCtx, usuarios]);

    const onClickGuardarUsuario = useCallback(async () => {
        const usuario = {
            uid,
            identificador: email,
            nombre,
            apellido,
            estado,
            desgloseTributario: desgloseTributario === 'si',
        };
        sesionCtx.cambiarLoading(true);
        try {
            let response;
            if (uid) {
                response = await enviar().put(`/usuarios/${sesionCtx.cliente.ruc}/`, usuario);
            } else {
                response = await enviar().post(`/usuarios/${sesionCtx.cliente.ruc}/`, usuario);
            }

            if (response.status === 201 || response.status === 200) {
                //const usuarioCreado = response.data;
                const respuestaOk = { tipo: 'N', cargaOk: true, errores: [], advertencias: [], informativas: ["Usuario guardado correctamente"] };
                setRespuesta(respuestaOk);
                setModalSimpleVisible(true);
                consultarUsuarios();
            } else {
                //alert("error al guardar usuario");
                const respuestaNoOk = { tipo: 'N', cargaOk: false, errores: ["Ha ocurrido un error al guardar"], advertencias: [], informativas: [] };
                setRespuesta(respuestaNoOk);
                setModalSimpleVisible(true);
            }
        } catch (error) {
            //alert("error al guardar usuario");
            console.log(error);
            const respuestaNoOk = { tipo: 'N', cargaOk: false, errores: [error.response.data.message], advertencias: [], informativas: [] };
            setRespuesta(respuestaNoOk);
            setModalSimpleVisible(true);
        } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [uid, nombre, apellido, estado, email, sesionCtx, desgloseTributario, consultarUsuarios]);

    const onCloseModal = () => {
        setModalVisible(false);
    };

    const deshabilitarBotonGuardar = () => {
        return (nombre === '' || apellido === '' || email === '') || errores.nombre != null || errores.apellido != null || errores.email != null;
    }

    const onCloseModalSimple = () => {
        setModalSimpleVisible(false);
        setModalVisible(false);
    };

    return (
        <Card className="derecha" titulo="GESTIÓN DE USUARIOS" descripcion="En esta pantalla puede configurar los usuarios de la plataforma">
            <ListUsuarios usuarios={usuarios} onClickEdit={onClickEditarUsuario} onChangeEstado={onChangeEstado} />
            <div className="botones">
                <ButtonAct variant="contained"
                    onClick={onClickCrearUsuario}>Crear Usuario</ButtonAct>
            </div>



            {modalVisible &&
                <Modal titulo="Datos de Usuario">
                    <FormAct id="frm-usuario">
                        <FormControl className='control'>
                            <TextFieldAct
                                required
                                id="txt-nombre"
                                placeholder="Ej. Juan"
                                label="Nombre"
                                helperText={errores.nombre}
                                value={nombre}
                                error={errores.nombre != null}
                                onChange={onChangeNombre}
                                autoComplete='off'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl className='control'>
                            <TextFieldAct
                                required
                                id="txt-apellido"
                                placeholder="Ej. Pérez"
                                label="Apellido"
                                helperText={errores.apellido}
                                value={apellido}
                                error={errores.apellido != null}
                                onChange={onChangeApellido}
                                autoComplete='off'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl className='control'>
                            <TextFieldAct
                                required={!uid}
                                disabled={uid}
                                id="txt-email"
                                placeholder="Ej. correo@empresa.com"
                                label="Email"
                                helperText={errores.email}
                                value={email}
                                error={errores.email != null}
                                onChange={onChangeEmail}
                                autoComplete='off'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl className='control'>
                            <FormLabel focused={false} className='label'>Desglose tributario Resumen OBD</FormLabel>
                            <RadioGroup row
                                name="rd-desglose"
                                value={desgloseTributario}
                                onChange={onChangeDesgloseTributario}>
                                <FormControlLabel className='radio-opcion' value="si" control={<Radio />} label="SI" />
                                <FormControlLabel className='radio-opcion' value="no" control={<Radio />} label="NO" />
                            </RadioGroup>
                        </FormControl>

                        <div className="botones">

                            <ButtonAct
                                variant="contained"
                                onClick={onClickGuardarUsuario}
                                disabled={deshabilitarBotonGuardar() || sesionCtx.loading}
                                startIcon={sesionCtx.loading && <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />}
                            >
                                Guardar
                            </ButtonAct>

                            <ButtonAct variant="contained" color="gris" onClick={onCloseModal}>
                                Cancelar
                            </ButtonAct>

                        </div>

                    </FormAct>


                </Modal>
            }

            {modalSimpleVisible && (
                <Modal respuesta={respuesta} titulo="Mensaje">
                    <div className="botones">
                        <ButtonAct variant="contained" color="gris" onClick={onCloseModalSimple}>
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            )}

        </Card>
    );
};

export default GestionDeUsuarios;
