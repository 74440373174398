import React from 'react';
import PropTypes from 'prop-types';
import './ItemHome.css';

const ItemHome = (props) => {
    return (
        <div className='itemHome' onClick={props.onClick}>
            <div className={props.classNameInterno}>
                <div>{props.titulo}</div>
                <img src={props.icono} alt={props.titulo} title={props.titulo}/>
            </div>
            <div className='itemHomeTexto'>{props.children}</div>
        </div>
    );
}

ItemHome.propTypes={
    onClick: PropTypes.func,
    classNameInterno:PropTypes.string,
    titulo:PropTypes.string,
    icono:PropTypes.string,
    children:PropTypes.node
  }

export default ItemHome;
