import React from 'react';
import PropTypes from 'prop-types';
import "./ListErrores.css";

import icono from '../assets/images/error.svg'

const ListErrores = ({respuesta}) => {
  if (respuesta.errores.length === 0) {
    return <span />;
  }

  return (
    <div>
      {respuesta.tipo==='A' && (
        <p className="parrafo" style={{ textAlign: 'left', marginTop:'16px', marginBottom:'6px' }}>
          El archivo presenta los siguientes <strong>errores</strong>, debe corregirlos y volver a cargar el archivo.
        </p>
      )}
      {respuesta.tipo==='N' && (
        <p className="parrafo" style={{ textAlign: 'left', marginTop:'16px', marginBottom:'6px' }}>
          Se ha presentado el siguiente error:
        </p>
      )}
      <div className="contenedor-modal">
        <div className="contenido-modal">
          <table className="errores">
            {respuesta.errores.map((item, index) => (
              <tr key={index}>
                <td><img src={icono} alt="Error" title="Error" /></td>
                <td>{item}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

ListErrores.propTypes={
  respuesta: PropTypes.shape({
    errores:PropTypes.shape([]),
    advertencias:PropTypes.shape([]),
    informativas:PropTypes.shape([]),
    cargaOk:PropTypes.bool,
    tipo:PropTypes.string
  })
}

export default ListErrores;