import React from 'react';
import PropTypes from 'prop-types';
import { Select, styled } from "@mui/material";

const StyleAct = styled(Select)(() => {
    return (
        {
            '& .MuiSelect-select': {
                color: '#151F47',
                fontFamily: 'Montserrat, sans-serif',
            },
            '& .MuiSvgIcon-root': {
                color: '#CB8D74',
            },


            '&.MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#CB8D74',

                },
                '&.Mui-focused fieldset': {
                    borderColor: '#CB8D74',
                },

            },
            '& .MuiFormHelperText-root': {
                fontFamily: 'Montserrat, sans-serif'
            }
        }

    );
})

const SelectAct = (props) => {
    return (
        <StyleAct
            id={props.id}
            labelId={props.labelId}
            placeholder={props.placeholder}
            label={props.label}
            type={props.type ? props.type : 'text'}
            autoComplete={props.autoComplete}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            error={props.error}
        >
            {props.children}
        </StyleAct>
    );
}

SelectAct.propTypes={
    id: PropTypes.string,
    labelId: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    error: PropTypes.bool,
    children: PropTypes.node
}

export default SelectAct;