import React from "react";
import firebase from "firebase/compat/app";
import firebaseConfig from "./config";
import Router from "./router";
import "./assets/css/style.css";

function App() {
  firebase.initializeApp(firebaseConfig);
  console.log("Ambiente:", process.env.REACT_APP_ENVIROMENT);
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
