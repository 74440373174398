import React from 'react';
import PropTypes from 'prop-types';

import './Card.css';

const Card = (props) => {
  const classes = 'card ' +props.className;

  return <div className={classes}>
    {props.titulo && <h1>{props.titulo}</h1>}
    {props.descripcion && <p>{props.descripcion}</p>}
    {props.children}
  </div>;
};

Card.propTypes={
  className: PropTypes.string,
  titulo:PropTypes.string,
  descripcion:PropTypes.string,
  children:PropTypes.node
}

export default Card;
