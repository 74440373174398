import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Switch, styled } from '@mui/material';
import iconoEditar from "../assets/images/editar.svg";

const StyledSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        color: '#DF5E2D',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#DF5E2D',
        opacity: 1,
    },
    '& .Mui-checked': {
        '&.MuiSwitch-switchBase': {
            color: '#60A629',
        },
        '&+.MuiSwitch-track': {
            backgroundColor: '#60A629!important',
            opacity: 1,
        }
    }
}));

const ListUsuarios = ({ usuarios, onClickEdit, onChangeEstado }) => {
    if (usuarios.length === 0) {
        return <p className="parrafo">No existen usuarios creados</p>;
    }

    return (
        <div>
            <table className="tabla">
                <caption>Usuarios Creados</caption>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Email</th>
                        <th>Estado</th>
                        <th>Editar</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((item, index) => (
                        <tr key={item.uid}>
                            <td>{item.nombre}</td>
                            <td>{item.apellido}</td>
                            <td>{item.identificador}</td>
                            <td>
                                <StyledSwitch checked={item.estado === 'A'} onChange={(e) => onChangeEstado(e, item, index)} />
                                <span>{item.estado === 'A' ? 'Activo' : 'Inactivo'}</span>
                            </td>
                            <td>
                                <ButtonBase onClick={() => onClickEdit(item)}>
                                    <img alt="Editar" title="Editar" src={iconoEditar}></img>
                                </ButtonBase>
                                {item.desgloseTributario === true && (
                                    <span
                                        style={{ fontSize: '10px', fontWeight: 'bold', padding: '3px', borderRadius: '14px', color: '#DF5E2D' }} 
                                        title="Desglose Tributario"
                                    >
                                        DT
                                    </span>
                                )}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

ListUsuarios.propTypes={
    usuarios: PropTypes.shape([]),
    onClickEdit:PropTypes.func,
    onChangeEstado:PropTypes.func
}

export default ListUsuarios;