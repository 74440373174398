import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { ErrorOutline } from '@mui/icons-material';
import enviarMultipart, { enviar } from '../../services/BaseService';
import SesionContext from '../../contexts/SesionContext';
import Card from "../common/Card";
import descargar from '../../assets/images/descargar.png';
import subir from '../../assets/images/subir.png';
import { CircularProgress, FormControl } from '@mui/material';
import TextFieldAct from '../mui/TextFieldAct';
import { appConfig } from '../../config';
import { validarFechaValoracion } from '../util/Regex';
import ButtonAct from '../mui/ButtonAct';
import Modal from '../common/Modal';
import { useNavigate } from 'react-router-dom';
import IconoTooltip from '../common/IconoTooltip'
import TooltipAct from '../mui/TooltipAct';

const CargaDeSalidas = (props) => {
    const sesionCtx = useContext(SesionContext);
    const navigate = useNavigate();
    const [firstLoad, setFirstLoad] = useState(true);
    const [fechaValoracion, setFechaValoracion] = useState('');
    const [ultimaCarga, setUltimaCarga] = useState('');
    const [fechaCorte, setFechaCorte] = useState('');
    const [errores, setErrores] = useState({ fechaCorte: null });
    const [clienteSplit, setClienteSplit] = useState('');
    const [cargaSplit, setCargaSplit] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [salidas, setSalidas] = useState([]);
    const [advertencias, setAdvertencias] = useState([]);
    const [respuesta, setRespuesta] = useState({ salidas: [], cargaOk: false, errores: [], advertencias: [], informativas: [] });
    const [mensajeError, setMensajeError] = useState(null);
    const [tituloModal, setTituloModal] = useState('');
    const [cargaInicial, setCargaInicial] = useState(false);
    const [textoReemplazar, setTextoReemplazar] = useState(null);
    const inputFile = useRef(null);

    const descargarFormatoSalidas = () => {
        const urlDescarga = `${appConfig.apiEndpont}historicos/formato-salidas`;
        window.open(urlDescarga, "_blank");
    };

    const getCargasSalidas = useCallback(async (ruc) => {
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviar().get(`/historicos/${ruc}/cargas-y-salidas/`);
            if (response.status === 200 && response.data.length > 0) {
                setCargaInicial(true);
                const cargasAnioV = response.data.sort((a, b) => {
                    if (a.anioValoracion > b.anioValoracion || a.mesValoracion > b.mesValoracion) {
                        return -1;
                    }
                    if (a.anioValoracion < b.anioValoracion || a.mesValoracion < b.mesValoracion) {
                        return 1;
                    }
                    return 0;
                });
                const anioValoracion = cargasAnioV[0].anioValoracion;
                const cargasValidacion = cargasAnioV.filter((c) => (
                    parseInt(c.anioValoracion, 10) === parseInt(anioValoracion, 10) && parseInt(c.anioCorte, 10) === 0 && parseInt(c.mesCorte, 10) === 0
                ));
                setFechaValoracion(cargasValidacion[0].fechaDeValoracionInterfaz);

                const cargasCorte = cargasAnioV.filter((c) => (c.anioValoracion === anioValoracion)).sort((a, b) => {
                    if (a.anioCorte > b.anioCorte || a.mesCorte > b.mesCorte) {
                        return -1;
                    }
                    if (a.anioCorte < b.anioCorte || a.mesCorte < b.mesCorte) {
                        return 1;
                    }
                    return 0;
                });
                if (parseInt(cargasCorte?.[0].anioCorte, 10) === 0 && parseInt(cargasCorte?.[0].mesCorte, 10) === 0) {
                    setUltimaCarga('No existen cargas de salidas');
                } else {
                    setUltimaCarga(cargasCorte[0].fechaDeCorteInterfaz);
                }
            } else {
                setCargaInicial(false);
            }
        } catch (error) {
            console.log(error);
            setCargaInicial(false);
        } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [sesionCtx]);

    const onCloseModal = () => {
        setModalVisible(false);
        inputFile.current.value = null;
    };

    const crearFormData = useCallback((archivoSeleccionado) => {
        const sep = ';;';
        const fd = new FormData();
        fd.append('archivo', archivoSeleccionado);
        let clienteSplitTmp = sesionCtx.cliente.id + sep + sesionCtx.cliente.ruc + sep + sesionCtx.cliente.nombre + sep;
        if (sesionCtx.cliente.nombreComercial != null) {
            clienteSplitTmp += sesionCtx.cliente.nombreComercial;
        }
        clienteSplitTmp += sep;
        setClienteSplit(clienteSplitTmp);
        fd.append('cliente', clienteSplitTmp);

        const [mesValoracion, anioValoracion] = fechaValoracion.split("/");
        const [mesCorte, anioCorte] = fechaCorte.split('/');

        let cargaSplitTmp = `${anioValoracion}${sep}${mesValoracion}${sep}${anioCorte}${sep}${mesCorte}${sep}${archivoSeleccionado.name}${sep}true`;
        setCargaSplit(cargaSplitTmp);
        fd.append('cargaSalida', cargaSplitTmp);
        return fd;
    }, [fechaValoracion, fechaCorte, sesionCtx.cliente]);

    const onChangeFechaCorte = useCallback((event) => {
        setTextoReemplazar(null);
        const corte = event.target.value;
        setFechaCorte(corte);
        const mensaje = validarFechaValoracion(corte);
        setErrores((anterior) => ({ ...anterior, fechaCorte: mensaje }));
        if (!mensaje) {
            const f = corte.split('/');
            const mesCorte = parseInt(f[0], 10);
            const anioCorte = parseInt(f[1], 10);
            const v = fechaValoracion.split('/');
            const mesValoracion = parseInt(v[0], 10);
            const anioValoracion = parseInt(v[1], 10);

            if (anioCorte < anioValoracion) {
                setErrores((anterior) => ({ ...anterior, fechaCorte: 'Fecha de carga de salidas incorrecta' }));
            } else if (anioCorte === anioValoracion && mesCorte <= mesValoracion) {
                setErrores((anterior) => ({ ...anterior, fechaCorte: 'Fecha de carga de salidas incorrecta' }));
            } else {
                setErrores((anterior) => ({ ...anterior, fechaCorte: null }));
                const ultimaCargaSplit = ultimaCarga.split('/');
                if (mesCorte === parseInt(ultimaCargaSplit[0], 10) && f[1] === ultimaCargaSplit[1]) {
                    setTextoReemplazar("Se va a reemplazar la carga");
                }

            }
        }
    }, [fechaValoracion]);

    const handleChangeCargaArchivo = useCallback(async (event) => {
        if (errores.fechaCorte || !fechaCorte) {
            inputFile.current.value = null;
            setTituloModal('Error');
            setErrores((anterior) => ({ ...anterior, fechaCorte: 'Fecha de carga de salidas incorrecta' }));
            setMensajeError('Fecha de carga de salidas no puede ser inferior a la fecha de valoración');
            setModalVisible(true);
            return;
        }
        setMensajeError(null);
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviarMultipart().post('/historicos/validar-archivo-salidas/', crearFormData(event.target.files[0]));
            const res = response.data;
            setRespuesta(res);
            sesionCtx.cambiarLoading(false);
            if (response.status === 200) {
                if (res.errores.length > 0) {
                    setTituloModal('Carga de Archivo');
                    setModalVisible(true);
                    // setAdvertencias(res.errores);
                    return;
                }
                if (res.advertencias.length > 0) {
                    setAdvertencias(res.advertencias);
                }
                if (res.salidas.length > 0) {
                    setSalidas(res.salidas);
                }
            } else {
                alert("Error al procesar archivo");
            }
        } catch (error) {
            alert("Error al procesar archivo");
            sesionCtx.cambiarLoading(false);
        } finally {
            inputFile.current.value = null;
        }
    }, [crearFormData, errores, fechaCorte, sesionCtx]);

    const onClickAdvertencias = useCallback(() => {
        setTituloModal('Carga de Archivo');
        setModalVisible(true);
    }, []);

    const onClickGuardarCarga = useCallback(async () => {
        const fd = new FormData();
        fd.append('cliente', clienteSplit);
        fd.append('cargaSalida', cargaSplit);
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviar().post('/historicos/guardar-salidas', fd);
            if (response.status === 200) {
                setRespuesta(response.data);
                setTituloModal('Información');
                setModalVisible(true);
                setSalidas([]);
                setClienteSplit('');
                setCargaSplit('');
            }
        } catch (error) {
            alert("Error al procesar archivo");
        } finally {
            sesionCtx.cambiarLoading(false);
            inputFile.current.value = null;
        }
    }, [clienteSplit, cargaSplit, sesionCtx]);

    const onClickCancelarCarga = useCallback(() => {
        setSalidas([]);
        setClienteSplit('');
        setCargaSplit('');
        inputFile.current.value = null;
    }, []);

    const onClickContinuar = () => {
        navigate('/base-consolidada');
    };

    const numberFormat = (number) => {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        return parseFloat(number).toFixed(2).toString().replace(exp, rep);
    }

    useEffect(() => {
        if (firstLoad && sesionCtx?.cliente?.ruc) {
            getCargasSalidas(sesionCtx?.cliente?.ruc);
            setFirstLoad(false);
        }
    }, [firstLoad, sesionCtx?.cliente?.ruc, getCargasSalidas]);

    return (
        <Card className="derecha" titulo="Carga de salidas" descripcion="Ingrese la información de salidas de empleados">
            {!cargaInicial ? (
                <div className="contenedor-flex">
                    <div style={{ fontSize: '14px', marginTop: '14px', fontWeight: 'bold', color: '#3061AA' }}>
                        No existe información cargada
                    </div>
                </div>
            ) : (
                <div className="contenedor-flex">
                    <div className="contenedor-pequeno">
                        <div className="contenedor-pasos" onClick={descargarFormatoSalidas}>
                            <div className="titulo">
                                <span>Paso 1</span>
                            </div>
                            <div className="contenido">
                                <div className="icono-carga">
                                    <div>
                                        <img src={descargar} alt="Complete con los datos que solicita el formato" />
                                        <p>Descargar formato de carga de salidas</p>
                                    </div>
                                </div>
                                <p>Completar todos los datos del formato</p>
                            </div>
                        </div>
                    </div>
                    <div className="contenedor-mediano">
                        <div className="contenedor-pasos">
                            <div className="titulo">
                                <span>Paso 2</span>
                            </div>
                            <div className="contenido">
                                <div className="form">
                                    <div>
                                        <span>
                                            Fecha de valoración:
                                            <TooltipAct placement="top" arrow 
                                                title={
                                                    <React.Fragment>
                                                        Fecha del último estudio actuarial disponible
                                                    </React.Fragment>
                                                }>
                                                <span><IconoTooltip /></span>
                                            </TooltipAct>

                                        </span></div>
                                    <div>{fechaValoracion}</div>
                                </div>
                                <div className="form">
                                    <div>
                                        <span>
                                            Última fecha de carga<br />de salidas:
                                            <TooltipAct placement="top" arrow
                                                title={
                                                    <React.Fragment>
                                                        Fecha de la última carga de salidas realizada por la empresa
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip /></span>
                                            </TooltipAct>

                                        </span>
                                    </div>
                                    <div>{ultimaCarga}</div>
                                </div>
                                <div className="form">
                                    <div><span>Fecha de carga de salidas:
                                        <TooltipAct placement="top" arrow
                                            title={
                                                <React.Fragment>
                                                    Fecha actual de la carga de salidas, en caso de colocar la misma fecha de la última carga, el archivo será reemplazado
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip /></span>
                                        </TooltipAct>
                                    </span>

                                    </div>
                                    <div>
                                        <FormControl className='control'>
                                            <TextFieldAct
                                                required
                                                id="txt-fecha-corte"
                                                placeholder="Ej. 12/2022"
                                                helperText={errores.fechaCorte || textoReemplazar}
                                                value={fechaCorte}
                                                error={errores.fechaCorte != null}
                                                onChange={onChangeFechaCorte}
                                                autoComplete='off'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contenedor-pequeno">
                        <div className="contenedor-pasos">
                            <div className="titulo">
                                <span>Paso 3</span>
                            </div>
                            <div className="contenido">
                                <label htmlFor="fl-cargar-archivo" className="icono-carga">
                                    <div>
                                        <img src={subir} alt="Cargar archivo de salidas" />
                                        <p>Cargar archivo de salidas</p>
                                    </div>
                                </label>
                                <p>Subir el formato con todos los datos completos</p>
                                <input ref={inputFile} type="file" id="fl-cargar-archivo" hidden onChange={handleChangeCargaArchivo}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {salidas.length > 0 && (
                <div>
                    <table className="table-salidas">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cédula</th>
                                <th>Motivo de Salida</th>
                                <th>Fecha de Salida</th>
                                <th>Pago Total de la Jubilación Patronal</th>
                                <th>Uso de la Jubilación Patronal (Únicamente si genera pagos)</th>
                                <th>Pago Total de Bonificación por Desahucio</th>
                                <th>Uso de provisión de Bonificación por Desahucio (Únicamente si generaron pagos)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salidas.map((salida, idx) => (
                                <tr key={`${idx}_${salida?.cedula}`}>
                                    <td>
                                        <div className="flex">
                                            {advertencias.length > 0 && (
                                                <ErrorOutline
                                                    size={20}
                                                    sx={{ color: '#DF5E2D', cursor: 'pointer' }}
                                                    onClick={onClickAdvertencias}
                                                />
                                            )}
                                            <span>{salida.nombre}</span>
                                        </div>
                                    </td>
                                    <td>{salida.cedula}</td>
                                    <td>{salida.motivoSalida}</td>
                                    <td>{salida.fechaSalida || 'S/F'}</td>
                                    <td>{numberFormat(salida.pagoTotalJubilacionPatronal)}</td>
                                    <td>{numberFormat(salida.usoProvisionJubilacionPatronal)}</td>
                                    <td>{numberFormat(salida.pagoTotalBonificacionDesahucio)}</td>
                                    <td>{numberFormat(salida.usoProvisionBonificacionDesahucio)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="contenedor-botones">
                        <ButtonAct
                            type="button"
                            variant="contained"
                            onClick={onClickGuardarCarga}
                            disabled={sesionCtx?.loading}
                            startIcon={sesionCtx?.loading && <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />}
                        >
                            GUARDAR INFORMACIÓN
                        </ButtonAct>
                        <ButtonAct variant="contained" color="gris" onClick={onClickCancelarCarga}>CANCELAR</ButtonAct>
                    </div>
                </div>
            )}

            {modalVisible &&
                <Modal respuesta={respuesta} titulo={tituloModal}>
                    {mensajeError && (
                        <div className=".mensaje-error">
                            <p>{mensajeError}</p>
                        </div>
                    )}
                    <div className="botones">
                        {respuesta.errores.length === 0 && respuesta.advertencias.length === 0 && <ButtonAct variant="contained" onClick={onClickContinuar} color="naranja">
                            Continuar
                        </ButtonAct>
                        }
                        <ButtonAct variant="contained" color="gris" onClick={onCloseModal}>
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            }
        </Card>
    );
}

export default CargaDeSalidas;