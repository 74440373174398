import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ComponentRouter = (props) => {
    return props.user ? props.children : <Navigate to="/" />;
};

ComponentRouter.propTypes={
    user:PropTypes.shape({
        email:PropTypes.string
    }),
    children: PropTypes.node
}

export default ComponentRouter;
