import React from 'react';
import iconoInfo from '../../assets/images/info.svg'
import iconoInfoBlanco from '../../assets/images/info-blanco.svg'
import PropTypes from 'prop-types';

const IconoTooltip = (props) => {

  let ancho = props.ancho;
  if (ancho == undefined) {
    ancho = 12;
  }


  return <img src={props.blanco ? iconoInfoBlanco : iconoInfo} alt="" title="" style={{ width: ancho, marginRight: 3, marginLeft: 3 }} />;
};

IconoTooltip.propTypes = {
  blanco: PropTypes.bool,
  ancho: PropTypes.number
}

export default IconoTooltip;
