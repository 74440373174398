import React from 'react';

import './SideLogin.css'
import facebook from '../../assets/images/facebook.png'
import twitter from '../../assets/images/twitter.png'
import instagram from '../../assets/images/instagram.png'
import linkedin from '../../assets/images/linkedin.png'
import seguridad from '../../assets/images/seguridad.png'
import verificacion from '../../assets/images/verificacion.png'


const SideLogin = (props) => {


    

    return <div className='side-login'>

        <h1 className='home-titulo'>SISTEMA DIGITAL DE PROVISIONES</h1>

        <h2>Beneficios</h2>

        <div className="social">
            <a href='https://www.facebook.com/actuariaconsultores/' rel="noreferrer" target='_blank'><img src={facebook} title="Facebook" alt="Facebook" /></a>
            <a href='https://twitter.com/ActuariaCia' rel="noreferrer" target='_blank'><img src={twitter} title="Twitter" alt="Twitter" /></a>
            <a href='https://www.instagram.com/actuaria_ec/' rel="noreferrer" target='_blank'><img src={instagram} title="Instagram" alt="Instagram" /></a>
            <a href='https://www.linkedin.com/company/actuariaconsultores/' rel="noreferrer" target='_blank'><img src={linkedin} title="Linkedin" alt="Linkedin" /></a>
        </div>

        <div>
            <div className='info-home'>
                <div className='side-login__icons'>
                    <img src={seguridad} title="Seguridad" alt="Seguridad" />
                </div>
                <div className='side-login__info'>
                    <p>SEGURIDAD de la información de su empresa. La data requerida para el estudio Actuarial se transmite mediante estándares de encriptación TLS utilizados en sitios web seguros, como los de las instituciones financieras</p>
                </div>
            </div>
            <div className='info-home'>
                <div className='side-login__icons'>
                    <img src={verificacion} title="Verificación" alt="Verificación" className='side-login__icons' />
                </div>
                <div className='side-login__info'>
                    <p>VERIFICACIÓN de datos en línea</p>
                </div>
            </div>

        </div>

    </div>;
};

export default SideLogin;
