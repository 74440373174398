import React from "react";

const SesionContext= React.createContext({
    admin:false,
    sitioAdmin:false,
    cliente:null,
    loading: false,
    clientesUsuario:null,
    cambiarCliente:(client)=>null,
    limpiarCliente:()=>{},
    cambiarSitioAdmin:(valor)=>{},
    cambiarLoading: (laod) => {},
    llenarClientesUsuario:(clientesUsuario)=>{},
});

export default SesionContext;

