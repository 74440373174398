import React from 'react';
import Card from './common/Card';
import PropTypes from 'prop-types';

const Error = ({error}) => {

    return (
      <Card>
        <div className="all-center">
          <span>Ha ocurrido un error</span>
        </div>
        {error!= null &&  <p>{error}</p>} 
      </Card>
    );
  }

  Error.propTypes={
    error: PropTypes.string
  }
  
  export default Error;