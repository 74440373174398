import React, { useState, useCallback, useContext, useEffect } from "react";
import { Autocomplete, Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, TextField } from "@mui/material";
import { InsertDriveFileOutlined, SimCardDownloadOutlined } from "@mui/icons-material";
import { enviar, enviarJson } from "../../services/BaseService";
import SesionContext from "../../contexts/SesionContext";
import SelectAct from "../mui/SelectAct";
import ButtonAct from "../mui/ButtonAct";
import FormAct from "../common/FormAct";
import Card from "../common/Card";
import AutocompleteAct from "../mui/AutocompleteAct";
import TooltipAct from "../mui/TooltipAct";
import IconoTooltip from "../common/IconoTooltip";

const ReporteAid = () => {
    const sesionCtx = useContext(SesionContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDetalle, setLoadingDetalle] = useState(false);
    const [loadingExcelGeneral, setLoadingExcelGeneral] = useState(false);
    const [loadingExcelDetalle, setLoadingExcelDetalle] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [fecha, setFecha] = useState(-1);
    const [tipoReporte, setTipoReporte] = useState(0);
    const [grupo, setGrupo] = useState(-1);
    const [fechaValoracion, setFechaValoracion] = useState(null);
    const [cargas, setCargas] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [reporte, setReporte] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    const [reporteDetalle, setReporteDetalle] = useState([]);
    const [cedula, setCedula] = useState(-1);
    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState('');

    const generarExcel = (fileName, base64) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            let slice = byteCharacters.slice(offset, offset + 512);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const reporteExcel = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(reporteExcel);
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const getCargas = useCallback(async () => {
        sesionCtx.cambiarLoading(true);
        try {
            const response = await enviar().get(`historicos/${sesionCtx.cliente.ruc}/cargas-y-salidas/`);
            if (response.status === 200) {
                setCargas(response.data);
            }
        } catch (error) { } finally {
            sesionCtx.cambiarLoading(false);
        }
    }, [sesionCtx]);

    const onChangeTipoReporte = (e) => setTipoReporte(e.target.value);

    const getGrupos = useCallback(async (carga) => {
        try {
            const response = await enviar().post(`historicos/grupos`, carga);
            if (response.status === 200) {
                setGrupos(response.data);
            } else {
                setGrupos([]);
            }
        } catch (error) {
            setGrupos([]);
        }
    }, []);

    const onChangeFecha = useCallback((event) => {
        const value = event.target.value;
        setFecha(value);
        if (value > -1) {
            const fechaSeleccionada = cargas[value];
            setFechaValoracion(fechaSeleccionada || null);
            getGrupos(fechaSeleccionada);
        } else {
            setFechaValoracion(null);
            setGrupos([]);
            setGrupo(-1);
        }
    }, [cargas, getGrupos]);

    const onChangeGrupo = (event) => setGrupo(event.target.value);

    const onChangeAutocompleteEmpleado = useCallback(async (event, newValue) => {
        if (newValue) {
            setLoadingDetalle(true);
            const value = newValue.cedula;
            console.log("valor:" + value);
            setCedula(value);
            if (value !== -1) {
                const emp = empleados?.filter((emp) => emp.cedula === value);
                setEmpleadoSeleccionado(emp[0])
                const grupoDto = grupo !== -1
                    ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
                    : null;
                const params = {
                    grupoDto,
                    tipoReporte,
                    cargaSalidaDto: fechaValoracion,
                    cedulaSeleccionada: value,
                };
                try {
                    const response = await enviarJson().post(`base-consolidada/reporte-aid-detalle/${sesionCtx.cliente.ruc}`, params);
                    if (response.status === 200) {
                        setReporteDetalle(response.data);
                    }
                    setLoadingDetalle(false);
                } catch (error) {
                    setLoadingDetalle(false);
                    setReporteDetalle([]);
                }
            } else {
                setLoadingDetalle(false);
                setReporteDetalle([]);
                setEmpleadoSeleccionado(null);
            }
        }

    }, [grupo, fechaValoracion, empleados, grupos, tipoReporte, sesionCtx.cliente.ruc]);


    const onChangeEmpleado = useCallback(async (event) => {
        setLoadingDetalle(true);
        const value = event.target.value;
        setCedula(value);
        if (value !== -1) {
            const emp = empleados?.filter((emp) => emp.cedula === value);
            setEmpleadoSeleccionado(emp[0])
            const grupoDto = grupo !== -1
                ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
                : null;
            const params = {
                grupoDto,
                tipoReporte,
                cargaSalidaDto: fechaValoracion,
                cedulaSeleccionada: value,
            };
            try {
                const response = await enviarJson().post(`base-consolidada/reporte-aid-detalle/${sesionCtx.cliente.ruc}`, params);
                if (response.status === 200) {
                    setReporteDetalle(response.data);
                }
                setLoadingDetalle(false);
            } catch (error) {
                setLoadingDetalle(false);
                setReporteDetalle([]);
            }
        } else {
            setLoadingDetalle(false);
            setReporteDetalle([]);
            setEmpleadoSeleccionado(null);
        }
    }, [grupo, fechaValoracion, empleados, grupos, tipoReporte, sesionCtx.cliente.ruc]);

    const onClickGenerar = useCallback(async () => {
        setLoading(true);
        setEmpleados([]);
        setReporte([]);
        setEmpleadoSeleccionado(null);
        setReporteDetalle([]);
        setCedula(-1);
        const grupoDto = grupo !== -1
            ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
            : null;
        const params = {
            grupoDto,
            tipoReporte,
            cargaSalidaDto: fechaValoracion,
            cedulaSeleccionada: null,
        };
        try {
            setGrupoSeleccionado(grupoDto != null ? grupoDto.nombre : 'Todos');
            const response = await enviarJson().post(`base-consolidada/reporte-aid/${sesionCtx.cliente.ruc}`, params);
            if (response.status === 200) {
                setEmpleados(response.data.empleados);
                setReporte(response.data.lista);
                setMensaje(response.data.lista.length > 0 ? '' : 'No existen trabajadores que pertenezcan al grupo seleccionado.');
            } else {
                setMensaje('No existen trabajadores que pertenezcan al grupo seleccionado.');
            }
            setLoading(false);
            //se pone el cursor al inicio del reporte
            const anchorElement = document.getElementById('reporte-aid');
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offsetTop: 0 });
            }

        } catch (error) {
            setLoading(false);
            setMensaje('No existen trabajadores que pertenezcan al grupo seleccionado.');
        }
    }, [grupo, fechaValoracion, grupos, tipoReporte, sesionCtx.cliente.ruc]);

    const onClickGenerarExcelGeneral = useCallback(async () => {
        setLoadingExcelGeneral(true);
        const grupoDto = grupo !== -1
            ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
            : null;
        const params = {
            grupoDto,
            tipoReporte,
            cargaSalidaDto: fechaValoracion,
            cedulaSeleccionada: null,
        };
        try {
            const response = await enviarJson().post(`base-consolidada/reporte-aid-excel/${sesionCtx.cliente.ruc}`, params);
            if (response.status === 200) {
                generarExcel(`Reporte Aid ${sesionCtx.cliente.ruc}`, response.data);
            }
        } catch (error) {
            console.log(error);
            alert('Error al generar el archivo');
        } finally {
            setLoadingExcelGeneral(false);
        }
    }, [grupo, fechaValoracion, grupos, tipoReporte, sesionCtx.cliente.ruc]);

    const onClickGenerarExcelDetalle = useCallback(async () => {
        setLoadingExcelDetalle(true);
        const grupoDto = grupo !== -1
            ? grupos.filter((gr) => gr.codigo === grupo)?.[0]
            : null;
        const params = {
            grupoDto,
            tipoReporte,
            cargaSalidaDto: fechaValoracion,
            cedulaSeleccionada: cedula,
        };
        try {
            const response = await enviarJson().post(`base-consolidada/reporte-aid-excel/${sesionCtx.cliente.ruc}`, params);
            if (response.status === 200) {
                generarExcel(`Reporte Detalle Aid ${sesionCtx.cliente.ruc} - ${cedula}`, response.data);
            }
        } catch (error) {
            console.log(error);
            alert('Error al generar el archivo');
        } finally {
            setLoadingExcelDetalle(false);
        }
    }, [grupo, fechaValoracion, grupos, cedula, tipoReporte, sesionCtx.cliente.ruc]);

    const numberFormat = (number) => {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        return parseFloat(number).toFixed(2).toString().replace(exp, rep);
    }

    useEffect(() => {
        if (firstLoad) {
            getCargas();
            setFirstLoad(false);
        }
    }, [getCargas, firstLoad]);

    return (
        <Card className="derecha" titulo="Reporte activo por impuestos diferidos de Jubilación Patronal y Bonificación por Desahucio" descripcion="Seleccione los datos para generar el reporte">
            <FormAct>
                <FormControl className='control'>
                    <InputLabel id="lbl-fecha-valoracion" className='input-label'>Fecha de valoración</InputLabel>
                    <SelectAct
                        labelId="lbl-fecha-valoracion"
                        id="sel-fecha-valoracion"
                        value={fecha}
                        label="Fecha de Valoración"
                        onChange={onChangeFecha}>
                        <MenuItem className="menuItem" value={-1}>Seleccione</MenuItem>
                        {cargas?.length > 0 && cargas?.map((carga, idx) => (
                            <MenuItem key={`${idx}_carga`} className="menuItem" value={idx}>
                                {carga?.fechaParaComboInterfaz}
                            </MenuItem>
                        ))}
                    </SelectAct>
                </FormControl>

                <FormControl className='control'>
                    <InputLabel id="lbl-tipo-reporte" className='input-label'>Impuesto diferido por</InputLabel>
                    <SelectAct
                        labelId="lbl-tipo-reporte"
                        id="sel-tipo-reporte"
                        value={tipoReporte}
                        label="Impuesto diferido por"
                        onChange={onChangeTipoReporte}>
                        <MenuItem className="menuItem" value={0}>Seleccione</MenuItem>
                        <MenuItem className="menuItem" value="J">Jubilación Patronal</MenuItem>
                        <MenuItem className="menuItem" value="D">Bonificación por Desahucio</MenuItem>
                    </SelectAct>
                </FormControl>

                <FormControl className='control'>
                    <InputLabel id="lbl-grupo" className='input-label'>
                        <span>Grupo
                            <TooltipAct placement="top" arrow
                                title={
                                    <React.Fragment>
                                        Grupos por estatus de trabajadores o por tiempo de servicio
                                    </React.Fragment>
                                }
                            >
                                <span><IconoTooltip blanco={false} ancho={16} /></span>
                            </TooltipAct>
                        </span>
                    </InputLabel>
                    <SelectAct
                        labelId="lbl-grupo"
                        id="sel-grupo"
                        value={grupo}
                        label="Grupo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        onChange={onChangeGrupo}>
                        <MenuItem className="menuItem" value={-1}>Todos</MenuItem>
                        {grupos?.length > 0 && grupos?.map((grupo) => (
                            <MenuItem key={grupo.codigo} className="menuItem" value={grupo.codigo}>{grupo.nombre}</MenuItem>
                        ))}
                    </SelectAct>
                    <FormHelperText sx={{ fontFamily: 'Montserrat' }}>Seleccionar el grupo de interés para generar el reporte</FormHelperText>
                </FormControl>

                <FormControl className='control-m0'>
                    <ButtonAct
                        variant="contained"
                        disabled={!fechaValoracion || !tipoReporte || loading}
                        onClick={onClickGenerar}
                        startIcon={loading ? <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} /> : <InsertDriveFileOutlined />}
                    >
                        Generar
                    </ButtonAct>
                </FormControl>
            </FormAct>

            <p className="informacion">
                El reporte de activos por impuesto diferidos presenta el detalle de los movimientos del activo por impuestos diferidos por Jubilación Patronal y Bonificación por Desahucio desde el año 2018 hasta la fecha de valoración, el cual se podrá generar por grupos de interés y posteriormente, por persona.
                <p style={{ marginTop: "6px" }}>TS: Tiempo de servicio</p>
            </p>
            <div id="reporte-aid">
                {reporte?.length > 0 && empleados?.length > 0 ? (
                    <div>
                        <table className="table-reporte">
                            <thead>
                                <tr>
                                    <th colSpan={5}>
                                        Reporte activo por impuestos diferidos  - {grupoSeleccionado}
                                    </th>
                                    <th colSpan={3}>
                                        <ButtonAct
                                            color="verde"
                                            endIcon={loadingExcelGeneral
                                                ? <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                                                : <SimCardDownloadOutlined />}
                                            sx={{ width: '150px' }}
                                            disabled={loadingExcelGeneral}
                                            onClick={onClickGenerarExcelGeneral}
                                        >
                                            Excel
                                        </ButtonAct>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Año</td>
                                    <td>Gasto deducible estimado</td>
                                    <td>Gasto no deducible permanente</td>
                                    <td>Gasto no deducible temporario</td>
                                    <td>Generación AID
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Cálculo anual del activo por impuestos diferidos de acuerdo al gasto no deducible temporario.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>

                                    </td>
                                    <td>Reversión AID
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Recuperación anual del activo por impuestos diferidos.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                    <td>
                                        Ajuste AID
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Ajuste o disminución anual del activo por impuestos diferidos.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                    <td>Saldo AID
                                        <TooltipAct placement="top" arrow color="blanco"
                                            title={
                                                <React.Fragment>
                                                    Saldo al final de cada año del activo por impuestos diferidos.
                                                </React.Fragment>
                                            }
                                        >
                                            <span><IconoTooltip blanco={true} /></span>
                                        </TooltipAct>
                                    </td>
                                </tr>
                                {reporte?.map((item, idx) => (
                                    <tr key={`${idx}_reporte`}>
                                        <td>{item.anio}</td>
                                        <td className={item.gastoDeducibleEstimado < 0 && 'text-red'}>
                                            {numberFormat(item.gastoDeducibleEstimado)}
                                        </td>
                                        <td className={item.gastoNoDeduciblePermanente < 0 && 'text-red'}>
                                            {numberFormat(item.gastoNoDeduciblePermanente)}
                                        </td>
                                        <td className={item.gastoNoDeducibleTemporario < 0 && 'text-red'}>
                                            {numberFormat(item.gastoNoDeducibleTemporario)}
                                        </td>
                                        <td className={item.generacionAid < 0 && 'text-red'}>
                                            {numberFormat(item.generacionAid)}
                                        </td>
                                        <td className={item.reversionAid < 0 && 'text-red'}>
                                            {numberFormat(item.reversionAid)}
                                        </td>
                                        <td className={item.ajusteAid < 0 && 'text-red'}>
                                            {numberFormat(item.ajusteAid)}
                                        </td>
                                        <td className={item.saldoAid < 0 && 'text-red'}>
                                            {numberFormat(item.saldoAid)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <p className="informacion">
                            AID: Activo por impuestos diferidos
                        </p>
                        <div className="empleados">
                            <h3>Seleccionar la persona de quien desea visualizar el reporte</h3>
                            <div>
                                <AutocompleteAct
                                    disablePortal
                                    freeSolo
                                    id="autocomplete-empleado"
                                    options={empleados}
                                    getOptionLabel={(empleado) => empleado.nombreParaAutocomplete}
                                    getOptionSelected={(empleado) => empleado.cedula}
                                    onChange={onChangeAutocompleteEmpleado}
                                    forcePopupIcon={true}
                                    renderInput={(params) => <TextField {...params} label="Búsqueda por nombre o cédula" InputLabelProps={{
                                        shrink: true,
                                    }} />}
                                />

                            </div>
                        </div>
                        {!loadingDetalle && reporteDetalle?.length > 0 && (
                            <table className="table-reporte-detalle">
                                <thead>
                                    <tr>
                                        <th colSpan={8}>
                                            <div>
                                                <p>EVOLUCIÓN PERIODO: 2018 - 2022</p>
                                                <p>VALORES EN US$</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <strong>NOMBRE:</strong>
                                            {` ${empleadoSeleccionado.nombre}`}
                                        </td>
                                        <td colSpan={2}>
                                            <strong>CÉDULA:</strong>
                                            {` ${empleadoSeleccionado.cedula}`}
                                        </td>
                                        <td colSpan={3}>
                                            <strong>STATUS:</strong>
                                            {` ${empleadoSeleccionado.status}`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>
                                            Reporte activo por impuestos diferidos  - {grupoSeleccionado}
                                        </td>
                                        <td colSpan={3}>
                                            <ButtonAct
                                                color="verde"
                                                endIcon={loadingExcelDetalle
                                                    ? <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                                                    : <SimCardDownloadOutlined />}
                                                sx={{ width: '150px' }}
                                                disabled={loadingExcelDetalle}
                                                onClick={onClickGenerarExcelDetalle}
                                            >
                                                Excel
                                            </ButtonAct>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Año</td>
                                        <td>Gasto deducible estimado</td>
                                        <td>Gasto no deducible permanente</td>
                                        <td>Gasto no deducible temporario</td>
                                        <td>Generación AID
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Cálculo anual del activo por impuestos diferidos de acuerdo al gasto no deducible temporario.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>Reversión AID
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Recuperación anual del activo por impuestos diferidos.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>Ajuste AID
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Ajuste o disminución anual del activo por impuestos diferidos.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                        <td>Saldo AID
                                            <TooltipAct placement="top" arrow color="blanco"
                                                title={
                                                    <React.Fragment>
                                                        Saldo al final de cada año del activo por impuestos diferidos.
                                                    </React.Fragment>
                                                }
                                            >
                                                <span><IconoTooltip blanco={true} /></span>
                                            </TooltipAct>
                                        </td>
                                    </tr>
                                    {reporteDetalle?.map((item, idx) => (
                                        <tr key={`${idx}_detalle`}>
                                            <td>{item.anio}</td>
                                            <td className={item.gastoDeducibleEstimado < 0 && 'text-red'}>
                                                {numberFormat(item.gastoDeducibleEstimado)}
                                            </td>
                                            <td className={item.gastoNoDeduciblePermanente < 0 && 'text-red'}>
                                                {numberFormat(item.gastoNoDeduciblePermanente)}
                                            </td>
                                            <td className={item.gastoNoDeducibleTemporario < 0 && 'text-red'}>
                                                {numberFormat(item.gastoNoDeducibleTemporario)}
                                            </td>
                                            <td className={item.generacionAid < 0 && 'text-red'}>
                                                {numberFormat(item.generacionAid)}
                                            </td>
                                            <td className={item.reversionAid < 0 && 'text-red'}>
                                                {numberFormat(item.reversionAid)}
                                            </td>
                                            <td className={item.ajusteAid < 0 && 'text-red'}>
                                                {numberFormat(item.ajusteAid)}
                                            </td>
                                            <td className={item.saldoAid < 0 && 'text-red'}>
                                                {numberFormat(item.saldoAid)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {loadingDetalle && (
                            <div className="div-center">
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="contenedor-flex">
                        <h3>{mensaje}</h3>
                    </div>

                )}

            </div>
            <div style={{ height: '800px' }}>{' '}</div>
        </Card>
    );
}

export default ReporteAid;