import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './common/Card';
import ItemHome from './ItemHome';

import iconoCargaSalidas from '../assets/images/inicio/carga-salidas.svg'
import iconoBaseConsolidada from '../assets/images/inicio/base-consolidada.svg'
import iconoAid from '../assets/images/inicio/reporte-aid.svg'
import iconoObd from '../assets/images/inicio/reporte-obd.svg'

const Inicio = (props) => {
    const navigate = useNavigate();

    const wrapper = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: '600px',
        margin: 'auto'
    }

    return (
        <Card className="derecha" titulo="Módulos de información">
            <div style={wrapper}>
                <ItemHome classNameInterno="itemHomeInterno1" titulo="Carga de salidas" icono={iconoCargaSalidas} onClick={() => navigate('/carga-de-salidas')}>
                    Importar salidas del período para visualizar los movimientos por reverso de provisión y AID.
                </ItemHome>

                <ItemHome classNameInterno="itemHomeInterno2" titulo="Base consolidada" icono={iconoBaseConsolidada} onClick={() => navigate('/base-consolidada')}>
                    Detalle de la composición histórica de la provisión de JP y BD.
                </ItemHome>

                <ItemHome classNameInterno="itemHomeInterno3" titulo="Reporte impuestos diferidos" icono={iconoAid} onClick={() => navigate('/reporte-aid')}>
                    Resumen de los movimientos del activo por impuestos diferidos de JP y BD por grupos de interés e individual.
                </ItemHome>

                <ItemHome classNameInterno="itemHomeInterno1" titulo="Reporte histórico de provisión" icono={iconoObd} onClick={() => navigate('/reporte-obd')}>
                    Resumen de la composición histórica de la provisión de JP y BD por grupos de interés e individual.
                </ItemHome>
            </div>
        </Card>
    );
};

export default Inicio;
