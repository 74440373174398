import React from 'react';
import PropTypes from 'prop-types';
import "./ListAdvertencias.css";

import icono from '../assets/images/alerta.svg'

const ListAdvertencias = (props) => {
  if (props.respuesta.advertencias.length === 0) {
    return <span />;
  }

  return (
    <div>
      <p className="parrafo" style={{ textAlign: 'left', marginTop:'16px', marginBottom:'6px'}}>
        El archivo presenta las siguientes <strong>advertencias</strong>, las puede corregir o las puede pasar por alto.
      </p>
      <div className="contenedor-modal">
        <div className="contenido-modal">
          <table className="advertencias">
            {props.respuesta.advertencias.map((item, index) => (
              <tr key={index}>
                <td><img src={icono} alt="Alerta" title="Alerta" /></td>
                <td>{item}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

ListAdvertencias.propTypes={
  respuesta: PropTypes.shape({
    errores:PropTypes.shape([]),
    advertencias:PropTypes.shape([]),
    informativas:PropTypes.shape([]),
    cargaOk:PropTypes.bool,
    tipo:PropTypes.string
  })
}

export default ListAdvertencias;