export const regexEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);
export const regexPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$');

export const regexFechaValoracion = new RegExp('[0-9]+/[0-9]+');

export const regexEntero = new RegExp('^[0-9]+$');

export const regexNombreOApellido = new RegExp('[A-Za-z]+');

export const regexPorcentaje=new RegExp('[0-9]+\\.{0,1}[0-9]+%');

export const validarNombreOApellido = (valor, mensajeError) => {

    let mensaje = null;

    if (!regexNombreOApellido.test(valor)) {
        mensaje = mensajeError;
    }
    return mensaje;

}

export const validarEmail = (email, mensajeError) => {

    let mensaje = null;

    if (!regexEmail.test(email)) {
        mensaje = mensajeError;
    }
    return mensaje;

}


export const validarFechaValoracion = (fechaValoracion) => {

    let mensaje = null;

    if (regexFechaValoracion.test(fechaValoracion)) {
        const [mes, anio] = fechaValoracion.split("/");
        mensaje = validarMes(mes);
        console.log("mes ensaje:" + mensaje);
        if (mensaje == null) {
            mensaje = validarAnio(anio);
            console.log("aca:"+mensaje);
        }
    } else {
        mensaje = "Formato debe ser MM/YYYY"
    }

    console.log("aca ult"+mensaje);
   
    return mensaje;
}

export const validarEntero = (entero, mensajeError) => {

    let mensaje = null;

    if (!regexEntero.test(entero)) {
        mensaje = mensajeError;
    }
    return mensaje;

}

export const validarAnio = (anio) => {

    const mensajeError = 'Año inválido';

    let mensaje = validarEntero(anio, mensajeError);

    if (mensaje == null) {
        if (anio < 2000 || anio > 2040) {
            mensaje = mensajeError;
        }
    }

    console.log("anio:"+mensaje);
    return mensaje;

}

export const validarMes = (mes) => {

    const mensajeError = 'Mes inválido';

    let mensaje = validarEntero(mes, mensajeError);

    if (mensaje == null) {
        if (mes < 1 || mes > 12) {
            mensaje = mensajeError;
        }
    }
    return mensaje;

}

export const validarPorcentaje = (valor, mensajeError) => {

    let mensaje = null;

    if (!regexPorcentaje.test(valor)) {
        mensaje = mensajeError;
    }
    return mensaje;

}

export const validarPassword = (valor, mensajeError) => {

    let mensaje = null;

    if (!regexPassword.test(valor)) {
        mensaje = mensajeError;
    }
    return mensaje;

}