import React from 'react';
import PropTypes from 'prop-types';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/material";

const StyleTooltipAct = styled(({ color, className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({color}) => {
    let fondo = "#ffffff";
    let texto = "#151F47";
    let fondoArrow = "#3061aa";
    let colorArrow = "#ffffff";

    if (color !== "blanco") {
        fondo = "#151F47";
        texto = "#fffff";
        fondoArrow = "#ffffff";
        colorArrow = "#151F47";
    }

    return (
        {

            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: fondo,
                color: texto,
                maxWidth: 220,
                fontFamily: 'Montserrat',
                fontSize: 12,
                border: '1px solid #dadde9',
            },
            [`& .${tooltipClasses.arrow}`]: {
                backgroundColor: fondoArrow,
                color: colorArrow,
            },
        })
});


const TooltipAct = ({ color, children, ...rest }) => {
    return (
        <StyleTooltipAct {...rest} color={color}>
            {children}
        </StyleTooltipAct>
    )
};

TooltipAct.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
    props: PropTypes.shape([])
}


export default TooltipAct;