import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../lib/firebase";
import Layout from '../components/template/Layout';
import Error from '../components/Error';
import Inicio from '../components/Inicio';
import Login from '../components/Login';
import Admin from '../components/pages/Admin';
import BaseConsolidada from '../components/pages/BaseConsolidada';
import CambiarPassword from '../components/pages/CambiarPassword';
import CargaDeArchivo from '../components/pages/CargaDeArchivo';
import CargaDeSalidas from '../components/pages/CargaDeSalidas';
import GestionDeUsuarios from '../components/pages/GestionDeUsuarios';
import ReporteAid from '../components/pages/ReporteAid';
import ReporteObd from '../components/pages/ReporteObd';
import SeleccionCliente from '../components/pages/SeleccionCliente';
import Reset from '../components/Reset';
import ComponentRouter from './ComponentRouter';

const Router = () => {
    const [user, loading] = useAuthState(auth);

    return (
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/seleccion-de-cliente" element={
              <ComponentRouter user={user}><SeleccionCliente /></ComponentRouter>
            } />
            <Route exact path="/carga-de-archivo" element={
              <ComponentRouter user={user}><CargaDeArchivo /></ComponentRouter>
            } />
            <Route exact path="/base-consolidada" element={
              <ComponentRouter user={user}><BaseConsolidada /></ComponentRouter>
            } />
            <Route exact path="/reporte-aid" element={
              <ComponentRouter user={user}><ReporteAid /></ComponentRouter>
            } />
            <Route exact path="/reporte-obd" element={
              <ComponentRouter user={user}><ReporteObd /></ComponentRouter>
            } />
            <Route exact path="/gestion-de-usuarios" element={
              <ComponentRouter user={user}><GestionDeUsuarios /></ComponentRouter>
            } />
            <Route exact path="/inicio" element={
              <ComponentRouter user={user}><Inicio /></ComponentRouter>
            } />
            <Route exact path="/cambiar-clave" element={
              <ComponentRouter user={user}><CambiarPassword /></ComponentRouter>
            } />
            <Route exact path="/carga-de-salidas" element={
              <ComponentRouter user={user}><CargaDeSalidas /></ComponentRouter>
            } />
             <Route exact path="/cambiar-password" element={
              <ComponentRouter user={user}><CambiarPassword /></ComponentRouter>
            } />
            <Route exact path="/error" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
}

export default Router;
