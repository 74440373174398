import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from "@mui/material";

const StyleButtonAct = styled(Button)(({ colorboton }) => {
    let colorTexto;
    let colorFondo;
    if (colorboton === 'gris') {
        colorTexto = '#fff';
        colorFondo = '#7E7E7E';
    } else if (colorboton === 'naranja') {
        colorTexto = '#fff';
        colorFondo = '#DF5E2D';
    } else if (colorboton === 'verde') {
        colorTexto = '#fff';
        colorFondo = '#60A629';
    } else {
        colorTexto = '#fff';
        colorFondo = '#3061AA';
    }
    return ({
        marginTop: '10px',
        fontFamily: 'Montserrat',
        color: colorTexto,
        backgroundColor: `${colorFondo}!important`,
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)!important'
        }
    });
})

const ButtonAct = ({color, children, ...rest}) => {
    return (
        <StyleButtonAct
            colorboton={color} 
            {...rest}
        >
            {children}
        </StyleButtonAct>
    );
}

ButtonAct.propTypes={
    color: PropTypes.string,
    children: PropTypes.node,
    rest:PropTypes.shape([])
}


export default ButtonAct;