import React, { useState, useEffect } from 'react';
import Card from '../common/Card';

import './MenuPanel.css'
import logoMenu from '../../assets/images/barco-grande.png'
import { MenuItem, MenuList, Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../lib/firebase";

const styleMenuItem = {
    fontFamily: 'Montserrat',
    padding: 0,
    '& a': {
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '13px',
        color: '#151F47',
        display: 'block',
        width: '100%',
        textAlign: 'left',
        padding: '15px 10px',
    },
    '&.MuiMenuItem-root.Mui-selected': {
        backgroundColor: '#DF5E2D'
    },
    '&.MuiMenuItem-root.Mui-selected a': {
        color: 'white',
    },
}

const MenuPanel = () => {
    const [user] = useAuthState(auth);
    const [admin, setAdmin] = useState(false);
    const location = useLocation();
    //const url = '/usuarios/es-rol-administrador/' + user.email;
    useEffect(() => {
        if (user) {
            user.getIdTokenResult()
                .then((idTokenResult) => {
                    console.log("menu panel token: " + idTokenResult)
                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.admin) {
                        // Show admin UI.
                        //showAdminUI();
                        setAdmin(true);
                        console.log("admin");
                    } else {
                        // Show regular user UI.
                        //showRegularUI();
                        setAdmin(false);
                        console.log("NO admin");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [user]);

    return <Card className='menu'>

        <img src={logoMenu} style={{width:'50px'}} title="Logo del Menú" alt="Logo del Menú" />
        <h1>MENÚ</h1>

        {admin &&

            <Paper sx={{ width: 320, maxWidth: '100%', boxShadow: 'none' }}>

                <MenuList >
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/seleccion-de-cliente'}><Link to='/seleccion-de-cliente'>Inicio</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/carga-de-archivo'}><Link to='/carga-de-archivo'>Carga de archivo</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/base-consolidada'}><Link to='/base-consolidada'>Base consolidada</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/reporte-aid'}><Link to='/reporte-aid'>Reporte impuestos diferidos</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/reporte-obd'}><Link to='/reporte-obd'>Reporte histórico de provisión</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/gestion-de-usuarios'}><Link to='/gestion-de-usuarios'>Gestión de usuarios</Link></MenuItem>
                </MenuList>
            </Paper>
        }

        {!admin &&
            <Paper sx={{ width: 320, maxWidth: '100%', boxShadow: 'none' }}>

                <MenuList >
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/inicio'}><Link to='/inicio'>Inicio</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/carga-de-salidas'}><Link to='/carga-de-salidas'>Carga de salidas</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/base-consolidada'}><Link to='/base-consolidada'>Base consolidada</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/reporte-aid'}><Link to='/reporte-aid'>Reporte impuestos diferidos</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/reporte-obd'}><Link to='/reporte-obd'>Reporte histórico de provisión</Link></MenuItem>
                    <MenuItem sx={styleMenuItem} selected={location.pathname === '/cambiar-password'}><Link to='/cambiar-password'>Cambiar contraseña</Link></MenuItem>

                </MenuList>
            </Paper>
        }
    </Card>;
};

export default MenuPanel;
