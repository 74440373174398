import React, { useState, useCallback } from "react";
import { CircularProgress, FormControl } from "@mui/material";
import { sendPasswordReset } from "../lib/firebase";
import Card from "./common/Card";
import FormAct from "./common/FormAct";
import SideLogin from "./common/SideLogin";
import ButtonAct from "./mui/ButtonAct";
import TextFieldAct from "./mui/TextFieldAct";
import "./Reset.css";

function Reset() {
  console.log("Olvido...")
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  const onClick = useCallback(async () => {
    setLoadingButton(true);
    try {
      await sendPasswordReset(email);
      setMensaje('Se le ha enviado un correo electrónico con los pasos a seguir');
    } catch (error) {
      console.log(error);
      setMensaje('Ha ocurrido un problema y no se ha podido enviar el correo electrónico');
    } finally {
      setLoadingButton(false);
    }
  }, [email]);

  return (
    <div>
      <SideLogin />
      <Card className="login" titulo="Cambio de contraseña" descripcion="Ingrese sus correo electrónico">
				<FormAct onSubmit={(event) => { event.preventDefault() }}>
					<div>
						{mensaje && <p className="error">{mensaje}</p>}
						<FormControl className="control">
							<TextFieldAct
								id="txt-email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								label="Correo electrónico"
								InputLabelProps={{
									shrink: true
								}}
							/>
						</FormControl>
						<FormControl>
							<ButtonAct
								variant="contained"
								onClick={onClick}
								disabled={!email || loadingButton}
								startIcon={(loadingButton) && <CircularProgress sx={{color: 'rgba(0, 0, 0, 0.26)'}} size={20} />}
							>
								ENVIAR
							</ButtonAct>
						</FormControl>
          </div>
        </FormAct>
      </Card>
    </div>
  );
}

export default Reset;